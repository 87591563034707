var stickyParent;

$( document ).ready(function() {
	$(".Disclaimer").click(function(){
		$("#footer-bottom-content").toggle();
		if ($("#footer-bottom-content").is(':visible')) {
		     //$("html, body").animate({scrollTop: $(".disclaimer").offset().top});
		 }
		$(this).find('i').toggleClass('fa-angle-down fa-angle-up');
	});
    $('#setMyPasswordButtonId').on('click', function(event) {
        event.preventDefault();
        var url = $(this).data('target');
        location.replace(url);
    });

	$(window).scroll(function () {
		showStickyButton();
	});
	showStickyButton();
	$('#sort-filterModal').on("scroll", function() {
		showStickyButton();
		$('#sort-filterModal .modal-body').css('padding-bottom', '50px');
	});

	$('#pricing-info-va-expandDetails').on('hidden.bs.collapse', function () {
		$('#pricing-info-va').addClass('fa-plus').removeClass('fa-minus');
	});
	$('#pricing-info-va-expandDetails').on('shown.bs.collapse', function () {
		$('#pricing-info-va').addClass("fa-minus").removeClass('fa-plus');
	});

	$(document).on( 'hidden.bs.collapse', '#pricing-info-va-expandDetails1', function(){
		$('#pricing-info-va').addClass('fa-plus').removeClass('fa-minus');
	});
	$(document).on( 'shown.bs.collapse', '#pricing-info-va-expandDetails1', function(){
		$('#pricing-info-va').addClass("fa-minus").removeClass('fa-plus');
	});
	$(document).on( 'hidden.bs.collapse', '#pricing-info-va-expandDetails2', function(){
		$('#pricing-info-va').addClass('fa-plus').removeClass('fa-minus');
	});
	$(document).on( 'shown.bs.collapse', '#pricing-info-va-expandDetails2', function(){
		$('#pricing-info-va').addClass("fa-minus").removeClass('fa-plus');
	});

	$('#pricing-info-va-expandDetails3').on('hidden.bs.collapse', function () {
		$('#pricing-info-va').addClass('fa-plus').removeClass('fa-minus');
	});
	$('#pricing-info-va-expandDetails3').on('shown.bs.collapse', function () {
		$('#pricing-info-va').addClass("fa-minus").removeClass('fa-plus');
	});
});

function searchResultDataPush(pCode, position) {
	if (window.location.href.indexOf("/search") !== -1) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'search-results-click',
			'productRmsId': pCode,
			'listPosition': parseInt(position)+1,
			'badges': $('span#'+pCode+'-data-badges').attr('data-badges')
		});
	}
}
function carouselDataPush(link, position) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event':'fp-carousel-click',
		'slide':position,
		'slide-href':link
	});
}
function pricingAndAvailabilityClick(pCode) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'view-pricing-availabilty-saved-tab-click',
        'savedItemProductId': pCode
    });
}

$(document).on('click','.accordionHead',function() {
	if ($(this).parent().siblings().find(".accordionBase").is(
		":visible")) {
		$(".accordionBase").hide();
		$(".accordionHead").find(".fa-minus").removeClass("fa-minus")
			.addClass("fa-plus");
	}
	if ($(this).parent().parent().siblings().children(".accordionBase")
		.is(":visible")) {
		$(".accordionHead").find(".fa-minus").removeClass("fa-minus")
			.addClass("fa-plus");
	}
	$(this).siblings().toggle();
	$(this).find(".togBenefit").toggleClass("fa-plus fa-minus");
});

//Script for BP Modal in order confirmation page
if($('#enableBPAutoPopup').val() === 'true'){
	setTimeout(function() {
		$('#open-bp-modal').trigger('click');
	}, 3000);
}

$('#open-bp-modal').on('click', function(){
	$('#bpModalconfirmationPageComponent #bpModalPriceId span').text($('#bpProductPrice').val());
	$('#bpModalconfirmationPageComponent #bpEnrollNowId').attr({'href': ACC.config.encodedContextPath + $('#bpProductUrl').val()});
	$('#bpModalconfirmationPageComponent').modal('show');
});

function showStickyButton() {
	if ($("body").hasClass("pageType-ProductPage")) {
		stickyParent = paymentOptionContainerId;
	} else if ($("body").hasClass("pageType-CategoryPage") || $("body").hasClass("page-racBrandProductPage") || $("body").hasClass("page-search") || $("body").hasClass("page-my-items") ) {
		if ($("#sort-filterModal").data("bs.modal") &&
			$("#sort-filterModal").data("bs.modal").isShown ) {
			stickyParent = "#sort-filterModal";
		}else {
			stickyParent = ".sort-filter-sticky";
		}
	}
	var top_of_element = $(stickyParent).find('.sticky-btn-wrap:visible').offset().top;
	var bottom_of_element = $(stickyParent).find('.sticky-btn-wrap:visible').offset().top + $(stickyParent).find('.sticky-btn-wrap:visible').height();
	var bottom_of_screen = $(window).scrollTop() + window.innerHeight;
	var top_of_screen = $(window).scrollTop();
	if (bottom_of_screen > top_of_element && top_of_screen <= bottom_of_element) {
		$(stickyParent + " .sticky-content").removeClass("sticky-btn");
	} else {
		$(stickyParent + " .sticky-content").addClass("sticky-btn");
	}
}

function displayErrorMessage(isEmptyMobileNumber) {
	let twilioValidationBox = $("#twilio-validation");
	$('#formWrapper1 .mobile').parent().addClass('mandatoryError');
	$('.error-message').css('display', 'block');
	if (isEmptyMobileNumber) {
		$('.enterMobileNumberError').css('display', 'block');
		$('.enterValidMobileNumberError').css('display', 'none');
	} else {
		$('.enterValidMobileNumberError').css('display', 'block');
		$('.enterMobileNumberError').css('display', 'none');
	}
	if (!$(twilioValidationBox).hasClass("hide")) {
		$(twilioValidationBox).addClass("hide");
	}
	$("#mobile-consent-com").addClass("hide");
	$('#formWrapper1 .mobile').siblings('span:first').removeClass('check-circle');
	errors++;
}