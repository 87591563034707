var errorMsgsFormfirst = {
	firstNameReq : ACC.firstNameRequired,
	firstNamePattern : ACC.firstNamePatternErr,
	lastNameReq : ACC.lastNameReq,
	lastNamePattern : ACC.lastNamePatternErr,
	phoneNoReq : ACC.phoneNoReq,
	phoneNoPattern : ACC.invalidPhonNo,
	cityReq : ACC.cityRequired,
	cityPattern : ACC.cityInvalidErr,
	stateReq : ACC.selectOption,
	zipcodeReq : ACC.zipcodeRequired,
	zipcodePattern : ACC.zipcodeInvalidErr,
	addrReq : ACC.addressRequired,
	addrPattern : ACC.addressPatternErr,
	aptPattern : ACC.apartmentNoInvalidErr,
	emailReq : ACC.emailReq,
	emailPattern : ACC.emailAddressPatternErr,
	emailVerify : ACC.emailAddressNotMatch,
	phonetypeReq : ACC.selectOption
};
function pageLoadRequirements() {
	$(".accountPersonalInformation .editAccount").show();
	$(".accountAddress .editAccount").show();
	$(".personalSection").show();
	$(".personalDataForm").hide();
	$(".addSection").show();
	$(".addDataForm").hide();
}

function checkLoginSessionTimeout(response){
	var data = response?JSON.parse(response):null;
	return data && data.sessionTimeout? true: false;
}

//Click or Toch Event, Need to move
jQuery.fn.extend({
	clickOrTouch: function(handler) {
		return this.each(function() {
			var event = ('ontouchstart' in document) ? 'touchstart' : 'click';
			$(this).on(event, handler);
		});
	}
});

$(document)
		.ready(
				function() {
				$('.sameEmailError').css('display','none');
					var $firstName = $("#firstName"), $lastName = $("#lastName"), $phoneType = $("input[name=phoneType]:checked"), $phoneNumber = $("#phoneNumber"), $city = $("#city"), $state = $("#stateDropDown"), $zipCode = $("#zipcode"), $address = $("#address");
					$phoneNumber.inputmask({
					    mask: '(999) 999-9999',
					    placeholder: ' ',
					    showMaskOnHover: false,
					    showMaskOnFocus: false,
					    positionCaretOnClick: 'none',
					    removeMaskOnSubmit: true,
					    jitMasking: true
					  });
					var	$email = $('#emailId'), $emailCheck = $('#emailId');
							//$verifyEmail = $('#verifyEmailId');
					$zipCode.inputmask({
					    mask: '99999',
					    placeholder: ' ',
					    showMaskOnHover: false,
					    showMaskOnFocus: false,
					    positionCaretOnClick: 'none',
					    removeMaskOnSubmit: true,
					    jitMasking: true
					  });
					pageLoadRequirements();

					$('#stateDropDown').on('focus', function(e) {
                        $("#stateDropDown option[value='']").prop('disabled', true);
                        $("#stateDropDown option[value='']").addClass('dropdownhide');
                        //if(!$(this).val()){
                        //$('#stateDropDown option:eq(1)').attr('selected', 'selected');
                        //}
                    });

                   /*$('#stateDropDown').clickOrTouch(function(e) {
                        if(!$(this).val()){
                            $("#stateDropDown option[value='']").remove();
                        }
                    });*/
					
					$("#cancelPersonalInfoBtn").on('click', function() {
						$(".accountPersonalInformation .editAccount").show();
						$(".personalSection").show();
						$(".personalDataForm").hide();
					});
					$("#cancelAddressInfoBtn").on('click', function() {
						$(".accountAddress .editAccount").show();
						$(".addSection").show();
						$(".addDataForm").hide();
					});

					$('#serviceDown').on('hidden.bs.modal', function (event) {
						$('body').css("padding-right", "0px");
					});

					// for psedu checkboxes
					$(".pseudo-radio-button-wrapper .pseudo-radio-button")
							.on(
									"click",
									function() {
										$(this)
												.parent('.radio-container')
												.parent(
														".pseudo-radio-button-wrapper")
												.find(
														'.application-radio-button')
												.each(
														function() {
															$(this)
																	.parent(
																			'.radio-container')
																	.parent(
																			".pseudo-radio-button-wrapper")
																	.find(
																			'a.pseudo-radio-button')
																	.removeClass(
																			'radio-selected');
															$(this).prop(
																	'checked',
																	false);
														});
										$(this).next('input[type="radio"]')
												.prop('checked', true);
										$(this).addClass('radio-selected');
									});
					// end
					$(".accountPersonalInformation .editAccount").on("click", function() {
						sessionTimeoutCall()
							.done(function(data, textStatus) {
								if(!checkLoginSessionTimeout(data)){
									$(".accountPersonalInformation .editAccount").hide();
									$(".personalSection").hide();
									$(".personalDataForm").show();
								}
								else{
									window.location.href = "/";
								}
							})
							.fail(function(jqXHR, textStatus, errorThrown) {
								$('#serviceDown').modal('show');
							})
							.always(function(){
							});
					});

					$(".accountAddress .editAccount").on("click", function() {
						sessionTimeoutCall()
							.done(function(data, textStatus) {
								if(!checkLoginSessionTimeout(data)){
									$(".accountAddress .editAccount").hide();
									$(".addSection").hide();
									$(".addDataForm").show();
								}
								else{
									window.location.href = "/";
								}
							})
							.fail(function(jqXHR, textStatus, errorThrown) {
								$('#serviceDown').modal('show');
							})
							.always(function(){
							});
					});

					$(".contactHelp").on("click", function() {
						/* */
						/*
						 * $('#passwordChange').modal({ backdrop: 'static',
						 * keyboard: false });
						 * $('#passwordChange').modal('show');
						 */

					});


					//update iframe height
					function updateChangePwdIframe(){
						$('#changePwdIframe').iframeHeight({
							resizeWaitTime       : 100,
							minimumHeight        : 440,
							defaultHeight        : 350,
							heightOffset         : 90,
							debugMode            : false,
							visibilitybeforeload : false,
							blockCrossDomain     : true,
							externalHeightName   : "bodyHeight",
							onMessageFunctionName: "getHeight",
							domainName           : "*",
							watcher              : true,
							watcherTime          : 100
						});
					}
					//updateChangePwdIframe();
					$('#passwordChange').on('show.bs.modal', function (event) {
						var emailAddress = $("#emailAddessValue").val();
						var changePasswordURL = $("#changePasswordURLValue").val()
						$("#changePwdIframe").remove();
						$('#changePwdBlock').append('<iframe src="'+changePasswordURL+'?id='+emailAddress+'" width="100%" scrolling="auto" id="changePwdIframe"></iframe>');
						updateChangePwdIframe();
					});


					// validation

					/*Parsley.addValidator('verifyEmail', {
						requirementType : 'string',
						priority : 31,
						validate : function(_value, requirement, instance) {
							return _value === ($(requirement).length ? $(
									requirement).val() : requirement);
						}
					});*/

					// For Personal Info Section
					if( $firstName.length ) {
						$firstName.parsley({
							trigger : null,
							errorsContainer : ".errorMsgFirstName",
							required : true,
							length : "[2,30]",
							pattern : "/^[A-Za-z' -.]*$/",
							requiredMessage : errorMsgsFormfirst.firstNameReq,
							patternMessage : errorMsgsFormfirst.firstNamePattern,
							lengthMessage : errorMsgsFormfirst.firstNameReq
						});
					}

					if( $lastName.length ) {
						$lastName.parsley({
							trigger : null,
							errorsContainer : ".errorMsgLastName",
							required : true,
							length : "[2,30]",
							pattern : "/^[A-Za-z' -.]*$/",
							requiredMessage : errorMsgsFormfirst.lastNameReq,
							patternMessage : errorMsgsFormfirst.lastNamePattern,
							lengthMessage : errorMsgsFormfirst.lastNameReq
						});
					}

					if( $phoneNumber.length ) {
						$phoneNumber
						.parsley({
							trigger : null,
							errorsContainer : ".errorMsgPhoneNo",
							required : true,
							length : "[14,14]",
							pattern : "/^[(]{1}[2-9]{1}[0-9]{2}[)]{1}[ ]{1}[0-9]{3}-[0-9]{4}$/",
							requiredMessage : errorMsgsFormfirst.phoneNoReq,
							patternMessage : errorMsgsFormfirst.phoneNoPattern,
							minlengthMessage : errorMsgsFormfirst.phoneNoReq,

						});
					}
					
					if( $phoneType.length ) {
						$phoneType.parsley({
							trigger : null,
							required : true,
							errorsContainer : ".errorMsgPhoneType",
							requiredMessage : errorMsgsFormfirst.phonetypeReq
						});	
					}

					// Personal Info Section Ends

					// For Address Info Section
					if( $address.length ) {
						$address.parsley({
							trigger : null,
							required : true,
							length : "[2,30]",
							pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:; ]*$/",
							errorsContainer : ".errorMsgAddr",
							requiredMessage : errorMsgsFormfirst.addrReq,
							lengthMessage : errorMsgsFormfirst.addrPattern,
							patternMessage : errorMsgsFormfirst.addrPattern
						});
					}

					/*$apartmentNumber.parsley({
						trigger : null,
						required : false,
						pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:; ]*$/",
						errorsContainer : ".errorMsgApt",
						patternMessage : errorMsgsFormfirst.aptPattern
					});*/
					if( $city.length ) {
						$city.parsley({
							trigger : null,
							required : true,
							length : "[2,20]",
							pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:; ]*$/",
							errorsContainer : ".errorMsgCity",
							requiredMessage : errorMsgsFormfirst.cityReq,
							lengthMessage : errorMsgsFormfirst.cityPattern,
							patternMessage : errorMsgsFormfirst.cityPattern
						});
					}

					if( $state.length ) {
						$state.parsley({
							trigger : null,
							required : true,
							errorsContainer : ".errorMsgState",
							requiredMessage : errorMsgsFormfirst.stateReq
						});
					}
					//TODO Arjun, verify this city
					if( $city.length ) {
					$city.parsley({
						trigger : null,
						required : true,
						length : "[2,20]",
						pattern: "/^[A-Za-z0-9\\\\/\\.,\\-()#&:;\u00C0-\u017F ']*$/",
						errorsContainer : ".errorMsgCity",
						requiredMessage : errorMsgsFormfirst.cityReq,
						lengthMessage : errorMsgsFormfirst.cityPattern,
						patternMessage : errorMsgsFormfirst.cityPattern
					});
					}

					if( $zipCode.length ) {
						$zipCode.parsley({
							trigger : null,
							required : true,
							length : "[5,5]",
							type : "digits",
							errorsContainer : ".errorMsgZipCode",
							requiredMessage : errorMsgsFormfirst.zipcodeReq,
							typeMessage : errorMsgsFormfirst.zipcodePattern,
							lengthMessage : errorMsgsFormfirst.zipcodeReq
						});
					}

					if( $email.length ) {
						$email.parsley({
							trigger : null,
							required : true,
							length : "[6,50]",
							//pattern : "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/",
							type : "email",
							errorsContainer : ".errorMsgEmail",
							requiredMessage : errorMsgsFormfirst.emailReq,
							typeMessage : errorMsgsFormfirst.emailPattern,
							lengthMessage : errorMsgsFormfirst.emailReq,
							patternMessage : errorMsgsFormfirst.emailPattern
						});
					}

					if( $emailCheck.length ) {
						$emailCheck.parsley({
							trigger : null,
							required : true,
							length : "[6,50]",
							//pattern : "/^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/",
							type : "email",
							errorsContainer : ".errorMsgEmailCheck",
							requiredMessage : errorMsgsFormfirst.emailReq,
							typeMessage : errorMsgsFormfirst.emailPattern,
							lengthMessage : errorMsgsFormfirst.emailReq,
							patternMessage : errorMsgsFormfirst.emailPattern
						});
					}

					/*$verifyEmail.parsley({
						trigger : null,
						required : true,
						length : "[6,50]",
						type : "email",
						verifyEmail : "#emailId",
						errorsContainer : ".errorMsgVerifyEmail",
						requiredMessage : errorMsgsFormfirst.emailReq,
						typeMessage : errorMsgsFormfirst.emailPattern,
						lengthMessage : errorMsgsFormfirst.emailReq,
						verifyEmailMessage : errorMsgsFormfirst.emailVerify
					});*/

					function validateEmailAddress() {
					$('.sameEmailError').css('display','none');
						emailInstance = $emailCheck.parsley();
						//verifyEmailInstance = $verifyEmail.parsley();

						emailInstance.validate();
						//verifyEmailInstance.validate();
						if (emailInstance.isValid()
								/*&& verifyEmailInstance.isValid()*/) {
							var shortEmailReqObj = {
								emailAddress : $email.val(),
								existingEmailAddress : $('.emailAccount')
										.text(),
								customerID : $('.customerIDHidden').text(),
								customerAccountID : $(
										'.customerAccountIDHidden').text()
							}
							$('.loadingIcon').show();
							sessionTimeoutCall()
								.done(function(data, textStatus) {
									if(!checkLoginSessionTimeout(data)){
										$.ajax({
												url : "/myaccount/updateemailaddress",
												type : "post",
												data : JSON.stringify(shortEmailReqObj),
												dataType : "json",
												contentType : 'application/json; charset=utf-8',
												success : function(data) {
														if (data.statusCode == "200") {
															$('#emailAddressChange').modal('hide');
															var newEmail=$email.val();
															$('.emailAccount').empty().text(newEmail);
															$('.emailFormInput').empty().text(newEmail);
															$('#usernameChange').modal({
																backdrop : 'static',
																keyboard : false
															});
															$('#usernameChange').modal(
																'show');
														}
														else if (data.statusCode == "400"){
															$('.sameEmailError').css('display','block');
														}
														else {
															$('#emailAddressChange').modal('hide');
															$('#serviceDown').modal({
																backdrop : 'dynamic',
																keyboard : false
															});
															$('#serviceDown').modal('show');
														}
												},
												error : function(error) {
													console.log(error);
													$('#emailAddressChange').modal('hide');
													$('#personal-info-edit').removeClass('hidden');
													$('body').addClass('modal-open');
													$('#personal-info-edit').modal('show');
													$(".processing-modal-persnl").addClass('hidden');
													$('.techDiffError').addClass('mandatoryError');
										            $('.techDiffError').css('display', 'block');
												},
												complete: function() {
													$('.loadingIcon').hide();
												}

											});
										console.log('success');
									}
									else{
										window.location.href = "/";
									}
								})
								.fail(function(jqXHR, textStatus, errorThrown) {
									//$('#serviceErrorPopup').modal('show');
								})
								.always(function(){

								});
						} else {
							/*
							 * $('html, body').animate({ scrollTop: $("
							 * .parsley-error").offset().top - 50 }, 1000);
							 */
						}

					}

//inline-error validation
	$("#personalInfoModal .formValid #phoneNumber").blur(function() {
	 var phoneNumberInstance = $phoneNumber.parsley();
	 phoneNumberInstance.validate();
	});
	var emailError = 0;
	var emailValidated = true;
	$("#personalInfoModal #emailId").blur(function() {
	emailError = 1;
	emailValidated = false;
	 var email = $("#emailId").val();
	 var emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/;
     if(email.trim() == "") {
    	 $('#emailId').parent().removeClass('has-success').addClass('has-error');
    	 $('#emailId').siblings('.successchk').removeClass('check-circle');
    	 $('.errorMsgEmail').removeClass('hide').addClass('show');
    	 emailError = 1;
    	 emailValidated = true;
     }else {
	 	if(emailRegex.test(email)) {
			$.ajax({
			    url: "/myaccount/submit-email-verification",
			    type: "GET",
				data: { email: $email.val()},
			    success: function(data) {
			    	if(data.result == "Valid") {
			    		$('#emailId').parent().removeClass('has-error').addClass('has-success');
			    		$('#emailId').siblings('.successchk').addClass('check-circle');
			    		$('.errorMsgEmail').removeClass('show').addClass('hide');
			    		emailError = 0;
			    		emailValidated = true;
			    	}
			    	else if(data.result == "Risky" || data.result == "Invalid"){
			    		$('#emailId').parent().removeClass('has-success').addClass('has-error');
			    		$('#emailId').siblings('.successchk').removeClass('check-circle');
			    		$('.errorMsgEmail').removeClass('hide').addClass('show');
			    		emailError = 1;
			    		emailValidated = true;
			    	}
			    	else {
			    		$('#emailId').parent().removeClass('has-error').addClass('has-success');
			    		$('#emailId').siblings('.successchk').addClass('check-circle');
			    		$('.errorMsgEmail').removeClass('show').addClass('hide');
			    		emailError = 0;
			    		emailValidated = true;
			    	}
			    },
			    error: function(error){
			    	console.log(data);
			    }
	  		});
  		}
  		else {
  			$('#emailId').parent().removeClass('has-success').addClass('has-error');
			$('#emailId').siblings('.successchk').removeClass('check-circle');
			$('.errorMsgEmail').removeClass('hide').addClass('show');
			emailError = 1;
			emailValidated = true;
  		}
	}
	});
	$("#personalInfoModal #city").blur(function() {
	 var cityInstance = $city.parsley();
	 cityInstance.validate();
	});
	$("#personalInfoModal #stateDropDown").blur(function() {
	 var stateInstance = $state.parsley();
	 stateInstance.validate();
	});
	$("#personalInfoModal #zipcode").blur(function() {
	 var zipCodeInstance = $zipCode.parsley();
	 zipCodeInstance.validate();
	});
	$("#personalInfoModal #address").blur(function() {
	 var addressInstance = $address.parsley();
	 addressInstance.validate();
	});
	
 $("#personalInfoModal #apartment_number").blur(function() {
		var aptCheck = $('#apartment_number').val();
		
			if (aptCheck.trim() === "") {
	          $('#apartment_number').parent().removeClass('has-success');
	          $('#apartment_number').parent().find('span').removeClass('check-circle');
	         
	        } else {
	          $('#apartment_number').parent().addClass('has-success');
        		$('#apartment_number').parent().find('span').addClass('check-circle');
	        }
	     });
//inline-error validation ends
					// Address Info section Ends
					function validateAndSubmitPersonalInfo() {
						if(emailValidated == false){
 							setTimeout(validateAndSubmitPersonalInfo, 250);
 						}
						phoneTypeInstance = $("input[name=phoneType]:checked").parsley();
						phoneNumberInstance = $phoneNumber.parsley();

						phoneNumberInstance.validate();
						phoneTypeInstance.validate();

						$('.sameEmailError').css('display','none');
						cityInstance = $city.parsley();
						stateInstance = $state.parsley();
						zipCodeInstance = $zipCode.parsley();
						addressInstance = $address.parsley();

						cityInstance.validate();
						stateInstance.validate();
						zipCodeInstance.validate();
						addressInstance.validate();
						if (phoneTypeInstance.isValid()
								&& phoneNumberInstance.isValid() 
								&& cityInstance.isValid() 
								&& stateInstance.isValid()
								&& zipCodeInstance.isValid()
								&& addressInstance.isValid()
								&& emailError == 0 ) {
							
							var accountAddressObj = {
								emailAddress : $email.val(),
								homeAddress : $address.val(),
								apartmentNumber : $('#apartment_number').val(),
								city : $city.val(),
								zipCode : $zipCode.val(),
								state : $state.val()
							};
							
							var personalInfoObj = {
									//firstName : $firstName.val(),
									//lastName : $lastName.val(),
									primaryPhoneType : $("input[name=phoneType]:checked").val(),
									contactTime : $('#preferedDownContact :selected').val(),
									//emailAddress : $('.emailAccount').text(),
									primaryPhoneNumber : $phoneNumber.val()
											.replace(/\D/g, ""),
									emailAddress : $email.val(),
									existingEmailAddress : $('.emailAccount')
											.text(),
									customerID : $('.customerIDHidden1').text(),
									customerAccountID : $(
											'.customerAccountIDHidden').text(),
									accountAddress : accountAddressObj
								};
							
							console.log(personalInfoObj);						
							$('#personal-info-edit').addClass('hidden');
							$('body').removeClass('modal-open');
						    $('.modal-backdrop').remove();
							$('#personal-info-edit').modal('hide');
							$(".processing-modal-persnl").removeClass('hidden');
							$(".processing-modal-persnl").modal({backdrop: 'static', keyboard: false});

							sessionTimeoutCall()
								.done(function(data, textStatus) {
									if(!checkLoginSessionTimeout(data)){
										$.ajax({
											url : "/myaccount/updatepersonalinfo",
											type : "post",
											data : JSON.stringify(personalInfoObj),
											dataType : "json",
											contentType : 'application/json; charset=utf-8',
											success : function(data) {
												console.log(data);
												
												if(data.result == "Invalid" || data.result == "Risky" ){
													
												 $('#personal-info-edit').removeClass('hidden');
													$('body').addClass('modal-open');
												   // $('.modal-backdrop').remove();
													$('#personal-info-edit').modal('show');
													$(".processing-modal-persnl").addClass('hidden');
													//$(".processing-modal-persnl").modal({backdrop: 'static', keyboard: false});
							
									    		  $('#emailId').parent().removeClass('has-success').addClass('has-error');
									    		  $('#emailId').siblings('.successchk').removeClass('check-circle');
									    		  $('.errorMsgEmail').removeClass('hide').addClass('show');
									    		}else if (data.serviceResponse == 200) {
														$('#success').modal({
															backdrop : 'dynamic',
															keyboard : false
														});
														
														$('#success').removeClass('hidden');
														$('#success').modal('show');
														/*
														updatePersonalInfoSave(data);
														$(
															".accountPersonalInformation .editAccount")
															.show();
														$(".personalSection").show();
														$(".personalDataForm").hide();
														$('#personalInfoToolTip').css(
															'display', 'block');
														$(
															".accountPersonalInformation .infoDispalySection")
															.css(
															{
																"margin-top" : "-81px"
															});
														setTimeout(
															function() {
																$(
																	'#personalInfoToolTip')
																	.css(
																	'display',
																	'none');
																$(
																	".accountPersonalInformation .infoDispalySection")
																	.css(
																	{
																		"margin-top" : "0px"
																	});
															}, 3000);
													*/} 
													else if (data.serviceResponse == 400) {
														$(
														".accountPersonalInformation .editAccount")
														.show();
													$(".personalSection").show();
													$(".personalDataForm").hide();
													$('#duplicateUser').modal({
														backdrop : 'dynamic',
														keyboard : false
													});
													$('#personal-info-edit').modal('hide');
													$('#duplicateUser').removeClass('hidden');
													$('#duplicateUser').modal('show');
													}
													else {
														$(
															".accountPersonalInformation .editAccount")
															.show();
														$(".personalSection").show();
														$(".personalDataForm").hide();
														$('#serviceDown').modal({
															backdrop : 'dynamic',
															keyboard : false
														});
														$('#personal-info-edit').modal('hide');
														$('#serviceDown').removeClass('hidden');
														$('#serviceDown').modal('show');
													}
											},
											error : function(error) {
												$('#personal-info-edit').removeClass('hidden');
												$('body').addClass('modal-open');
												$('#personal-info-edit').modal('show');
												$(".processing-modal-persnl").addClass('hidden');
												$('.techDiffError').addClass('mandatoryError');
												$('.techDiffError').css('display', 'block');
											},
											complete : function() {
												$('.processing-modal-persnl').addClass('hidden');
												$(".processing-modal-persnl").modal('hide');
											}
										});
									}
									else{
										//window.location.href = "/";
									}
								})
								.fail(function(jqXHR, textStatus, errorThrown) {
									//$('#serviceErrorPopup').modal('show');
								})
								.always(function(){
								});
						}

						else {
							$('html, body').animate(
									{
										scrollTop : $(".has-error")
												.offset().top - 50
									}, 1000);
						}
					}
					function validateAndSubmitAddressInfo() {
						cityInstance = $city.parsley();
						stateInstance = $state.parsley();
						zipCodeInstance = $zipCode.parsley();
						addressInstance = $address.parsley();
						//appartmentInstance = $apartmentNumber.parsley();

						cityInstance.validate();
						stateInstance.validate();
						zipCodeInstance.validate();
						addressInstance.validate();
						appartmentInstance.validate();

						if (cityInstance.isValid() && stateInstance.isValid()
								&& zipCodeInstance.isValid()
								&& addressInstance.isValid() && appartmentInstance.isValid()) {
							var addressInfoObj = {
								'homeAddress' : $address.val(),
								'apartmentNumber' : $('#apartment_number').val(),
								'city' : $city.val(),
								'zipCode' : $zipCode.val(),
								'state' : $state.val(),
								'emailAddress' : $('.emailAccount').text()
							};
							console.log("JSON: ", JSON
									.stringify(addressInfoObj));
							$('.loadingIcon').show();
							sessionTimeoutCall()
								.done(function(data, textStatus) {
									if(!checkLoginSessionTimeout(data)){
										$.ajax({
											url : "/myaccount/normalizeaddress",
											type : "post",
											data : JSON.stringify(addressInfoObj),
											contentType : 'application/json',
											success : function(data) {
												console.log("in success", data);
												if (data.serviceResponse == 200) {
													console.log("data.ansCheck",
														data.ansCheck);
													console.log("data.asdas",
														data.serviceResponse);
													if (data.ansCheck == "true") {
														updateANSPopUp(
															data.normalizedAddress,
															data.normalAddress);
														newObjectAMSCall(
															data.normalizedAddress,
															data.normalAddress);
														$('#addressNormalisation')
															.modal(
															{
																backdrop : 'static',
																keyboard : false
															});
														$('#addressNormalisation')
															.modal('show');
													} else {
														updateAddressInfoSave(data.normalAddress);
														$(
															".accountAddress .editAccount")
															.show();
														$(".addSection").show();
														$(".addDataForm").hide();
														$('#personalAddToolTip')
															.css('display',
															'block');
														$(
															".accountAddress .infoDispalySection")
															.css(
															{
																"margin-top" : "-81px"
															});
														setTimeout(
															function() {
																$(
																	'#personalAddToolTip')
																	.css(
																	'display',
																	'none');
																$(
																	".accountAddress .infoDispalySection")
																	.css(
																	{
																		"margin-top" : "0px"
																	});
															}, 3000);
													}
												} else {
													if (data.ansCheck == "true") {
														console
															.log("ANS check failed");
														updateAddressInfoSave(data.normalAddress);
														$(
															".accountAddress .editAccount")
															.show();
														$(".addSection").show();
														$(".addDataForm").hide();
														$('#serviceDown').modal({
															backdrop : 'dynamic',
															keyboard : false
														});
														$('#serviceDown').modal(
															'show');
													} else {
														updateAddressInfoSave(addressInfoObj);
														$(
															".accountAddress .editAccount")
															.show();
														$(".addSection").show();
														$(".addDataForm").hide();
														$('#personalAddToolTip')
															.css('display',
															'block');
														$(
															".accountAddress .infoDispalySection")
															.css(
															{
																"margin-top" : "-81px"
															});
														setTimeout(
															function() {
																$(
																	'#personalAddToolTip')
																	.css(
																	'display',
																	'none');
																$(
																	".accountAddress .infoDispalySection")
																	.css(
																	{
																		"margin-top" : "0px"
																	});
															}, 3000);
													}

												}

											},
											error : function(error) {
												console.log("in error", error);
												$('#personal-info-edit').removeClass('hidden');
												$('body').addClass('modal-open');
												$('#personal-info-edit').modal('show');
												$(".processing-modal-persnl").addClass('hidden');
												$('.techDiffError').addClass('mandatoryError');
												$('.techDiffError').css('display', 'block');
											},
											complete : function() {
												console.log("complete");
												$('.loadingIcon').hide();
											}
										});
									}
									else{
										window.location.href = "/";
									}
								})
								.fail(function(jqXHR, textStatus, errorThrown) {
									//$('#serviceErrorPopup').modal('show');
								})
								.always(function(){
								});
							/*
							 * $(".accountAddress .editAccount").show();
							 * $(".addSection").show();
							 * $(".addDataForm").hide();
							 * $('#personalAddToolTip').css('display', 'block');
							 */

						}

						else {
							$('html, body').animate(
									{
										scrollTop : $(".has-error")
												.offset().top - 50
									}, 1000);
						}
					}

					$("#savePersonalInfoBtn").on(
							"click", function() {
								setTimeout(validateAndSubmitPersonalInfo(), 3000);
							});
					$(".accountAddress #savePersonalInfoBtn").on("click",
							function() {
								validateAndSubmitAddressInfo();
							});
					// $("span#select_user_full_address_link").on("click",function(){
					// $('#addressNormalisation').modal('hide');
					// $(".accountAddress .editAccount").show();
					// $(".addSection").show();
					// $(".addDataForm").hide();
					// $('#personalAddToolTip').css('display', 'block');
					// $(".accountAddress
					// .infoDispalySection").css({"margin-top":"-81px"});
					// setTimeout(function(){
					// $('#personalAddToolTip').css('display', 'none');
					// $(".accountAddress
					// .infoDispalySection").css({"margin-top":"0px"});
					// },3000);
					// });
					// $("span#select_suggested_full_address_link").on("click",function(){
					//        
					// });
					$("#usernameChangeIDP").on("click", function() {
						sessionTimeoutCall()
							.done(function(data, textStatus) {
								if(!checkLoginSessionTimeout(data)){
									$('#emailAddressChange').modal({
										backdrop : 'static',
										keyboard : false
									});
									$('#emailAddressChange').modal('show');
									$("#emailAddressChange #emailId").val("");
									//$("#emailAddressChange #verifyEmailId").val("");
								}
								else{
									window.location.href = "/";
								}
							})
							.fail(function(jqXHR, textStatus, errorThrown) {
								$('#serviceDown').modal('show');
							})
							.always(function(){
							});
					});
					$('.emailSubmit').on('click', function() {
						validateEmailAddress();
					});
					$("#passwordChangeIDP").on("click", function() {
						sessionTimeoutCall()
							.done(function(data, textStatus) {
								if(!checkLoginSessionTimeout(data)){
									$('#passwordChange').modal({
										backdrop : 'dynamic',
										keyboard : false
									});
									$('#personal-info').modal('hide');
									$('#personal-info').addClass('hidden');
									$('#passwordChange').removeClass('hidden');
									$('#passwordChange').modal('show');
								}
								else{
									window.location.href = "/";
								}
							})
							.fail(function(jqXHR, textStatus, errorThrown) {
								$('#serviceDown').modal('show');
							})
							.always(function(){
							});
					});
					$("#serviceDownCloseBtn").on("click", function() {
						$('#serviceDown').modal('hide');
					});
					$("#successCloseBtn").on("click", function() {
						$('#success').modal('hide');
					});
					$("#usernameCloseBtn").on("click", function() {
						$('#usernameChange').modal('hide');
                        $('body').css("padding-right", "0px");
					});
					$("#passwordCloseBtn").on("click", function() {
						$('#passwordChange').modal('hide');
					});

					//Handle timeout AJAX error
					$( document ).ajaxError(function( event, jqxhr, settings, thrownError ) {
						if ( jqxhr.statusText === "timeout" ) {
							$(".modal").modal('hide');
							$('#serviceDown').modal({
								backdrop : 'dynamic',
								keyboard : false
							});
							$('#serviceDown').modal('show');
						}
					});
                    //Truncate store address
					$("p.storeZipCode").text($("p.storeZipCode").text().substring(0,5));
					$('#migratePaymentMethod').modal({keyboard : false,backdrop : 'static'});
					if($("#addPaymentMethodFlag").val() === true || $("#addPaymentMethodFlag").val() == "true"){
				        var checkStorelocator = setInterval(function() {
				        	if($("div#qtip-0").length > 0 || $("div#qtip-1").length > 0){
				                $("div#qtip-0, div#qtip-1, #qtip-overlay").hide();
				                $("#mobileNavMapButton").removeClass("open");
				                clearInterval(checkStorelocator);
				            }
				        }, 100);
				        $("#migratePaymentMethod a.btn").on("contextmenu", function(){
				        	return false;
				        });
				    }
				});

function newObjectAMSCall(normAdd, nonNormAdd) {
	$("#select_suggested_full_address_link").on('click', function() {
		selectedAddressUpdate(normAdd);

	});
	$("#select_user_full_address_link").on('click', function() {
		selectedAddressUpdate(nonNormAdd);

	});
}

function updatePersonalInfoSave(data) {
	//$(".firstNameAccount").empty().text(data.firstName);
//	$("#accountDetails .customer-name").empty().text(data.firstName + " "+data.lastName);
//	var welcomeName = "Hi " + data.firstName + "!";
//	$(".profileName.visible-xs span.left").empty().text(welcomeName);
//	$(".profileName.hidden-xs").empty().text(welcomeName);
	//$("#firstName").val(data.firstName);
	//$(".lastNameAccount").empty().text(data.lastName);
	//$("#lastName").val(data.lastName);
	if (data.primaryPhoneNumber !== null) {
		var preFormNumber = data.primaryPhoneNumber;
		var formNumber = "(" + preFormNumber.substring(0, 3) + ") "
				+ preFormNumber.substring(3, 6) + "-"
				+ preFormNumber.substring(6, 10);
		$(".phoneNumber").empty().text(formNumber);
		$("#phoneNumber").val(formNumber);
	}
	if (data.primaryPhoneType != null && data.primaryPhoneType != undefined) {
		if (data.primaryPhoneType == "1") {
			$(".mobileSec").empty().html("Home");
			$("#phoneType").val(1);
		} else if (data.primaryPhoneType == "2") {
			$(".mobileSec").empty().text("Mobile");
			$("#phoneType").val(2);
		} else {
			$(".mobileSec").empty().text("Work");
			$("#phoneType").val(3);
		}
	}
	if (data.contactTime != null && data.contactTime != undefined) {
		if (data.contactTime == "1") {
			$(".preferedTimeAcoount span").empty().html(ACC.preferredtimeMorning);
		} else if (data.contactTime == "2") {
			$(".preferedTimeAcoount span").empty().text(ACC.preferredtimeAfternoon);
		} else {
			$(".preferedTimeAcoount span").empty().text(ACC.preferredtimeEvening);
		}
	}
}
function updateAddressInfoSave(data) {
	var addrData = data;
	if (addrData.apartmentNumber != null
			&& addrData.apartmentNumber != undefined) {
		addrDataAprtmntNumber = addrData.apartmentNumber;
	} else {
		addrDataAprtmntNumber = "";
	}
	$(".streetName").empty().text(addrData.homeAddress);
	$("#address").val(addrData.homeAddress);
	$(".apartmentName").empty().text(addrDataAprtmntNumber);
	$("#apartment_number").val(addrDataAprtmntNumber);
	$(".regionStateName").empty().text(addrData.city + ", " + addrData.state);
	$("#city").val(addrData.city);
	$("#stateDropDown").val(addrData.state);
	$(".zipCode").empty().text(addrData.zipCode);
	$("#zipcode").val(addrData.zipCode);
}

function updateANSPopUp(dataNormalised, dataNonNormalised) {

	if (dataNormalised.apartmentNumber != null) {
		dataNormAprtmntNumber = dataNormalised.apartmentNumber;
	} else {
		dataNormAprtmntNumber = "";
	}
	if (dataNonNormalised.apartmentNumber != null) {
		dataNonNormAprtmntNumber = dataNonNormalised.apartmentNumber;
	} else {
		dataNonNormAprtmntNumber = "";
	}

	$("#suggested_full_address p:nth-child(2)").empty().text(
			dataNormAprtmntNumber + " " + dataNormalised.homeAddress);
	$("#suggested_full_address p:nth-child(3)").empty().text(
			dataNormalised.city + " " + dataNormalised.state + " "
					+ dataNormalised.zipCode);
	$("#user_full_address p:nth-child(2)").empty().text(
			dataNonNormAprtmntNumber + " " + dataNonNormalised.homeAddress);
	$("#user_full_address p:nth-child(3)").empty().text(
			dataNonNormalised.city + " " + dataNonNormalised.state + " "
					+ dataNonNormalised.zipCode);
}
function selectedAddressUpdate(data) {
	// var responseANS=data;
	var addressInfoUpdateANS = data;
	console.log("aa gyi", addressInfoUpdateANS);
	$('.loadingIcon').show();
	sessionTimeoutCall()
		.done(function(data, textStatus) {
			if(!checkLoginSessionTimeout(data)){
				$.ajax({
					url : "/myaccount/updateaddress",
					type : "post",
					data : JSON.stringify(addressInfoUpdateANS),
					contentType : 'application/json',
					success : function(data) {
						console.log(data);
						if (data.AMS003Status == 200) {
							updateAddressInfoSave(addressInfoUpdateANS);
							$('#addressNormalisation').modal('hide');
							$(".accountAddress .editAccount").show();
							$(".addSection").show();
							$(".addDataForm").hide();
							$('#personalAddToolTip').css('display', 'block');
							$(".accountAddress .infoDispalySection").css({
								"margin-top" : "-81px"
							});
							setTimeout(function() {
								$('#personalAddToolTip').css('display', 'none');
								$(".accountAddress .infoDispalySection").css({
									"margin-top" : "0px"
								});
							}, 3000);
						} else {
							$('#addressNormalisation').modal('hide');
							$(".accountAddress .editAccount").show();
							$(".addSection").show();
							$(".addDataForm").hide();

							$(".accountAddress .editAccount").show();
							$(".addSection").show();
							$(".addDataForm").hide();
							$('#serviceDown').modal({
								backdrop : 'dynamic',
								keyboard : false
							});
							$('#serviceDown').modal('show');
						}
					},
					error : function(error) {
						console.log(error);
						$('#personal-info-edit').removeClass('hidden');
						$('body').addClass('modal-open');
						$('#personal-info-edit').modal('show');
						$(".processing-modal-persnl").addClass('hidden');
						$('.techDiffError').addClass('mandatoryError');
						$('.techDiffError').css('display', 'block');
						
					},
					complete : function() {
						console.log("complete");
						$('.loadingIcon').hide();
					}
				});
			}
			else{
				window.location.href = "/";
			}
		})
		.fail(function(jqXHR, textStatus, errorThrown) {
		})
		.always(function(){
		});
}


$('#personalInfoEditBtn').on('click',function(){
	$('#personal-info').addClass('hidden');
	$('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
	$('#personal-info').modal('hide');
	$("#personal-info-edit").removeClass('hidden');
	$('#personal-info-edit').modal('show');
	
});

$('.processing-modal').on('shown.bs.modal', function() {
	$('.modal-backdrop').css('background-color', 'black');
});
$('#duplicateUser').on('shown.bs.modal', function() {
	$('#duplicateUser').css("padding-right", "0px");
});
$('#passwordChange').on('shown.bs.modal', function() {
	$('#passwordChange').css("padding-right", "0px");
});
$('#success').on('shown.bs.modal', function() {
	$('.modal-backdrop').css('background-color', 'black');
	$('#success').css("padding-right", "0px");
});
$('#personalInfoModal').on('hidden.bs.modal', function() {
	if($('#personalInfoModal').css('display') == 'none') {
	$('.modal-backdrop').remove();
	$('body').css("padding-right", "0px");
	}
	if($('#success').css('display') == 'block') {
		var targetURL = window.location.href.split("?")[0];
		window.location.href=targetURL;
	}
});