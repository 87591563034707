ACC.cartremoveitem = {

	bindAll: function ()
	{
		this.bindCartRemoveProduct();
	},
	bindCartRemoveProduct: function ()
	{

		$('.submitRemoveProduct').on("click", function ()
		{
			var prodid = $(this).attr('id').split("_")
			var form = $('#updateCartForm' + prodid[1]);
			var productCode = form.find('input[name=productCode]').val(); 
			var initialCartQuantity = form.find('input[name=initialQuantity]');
			var cartQuantity = form.find('input[name=quantity]');
			var cartData = form.data("cart");
			ACC.track.trackRemoveFromCart(productCode, initialCartQuantity.val(),cartData);
			
			cartQuantity.val(0);
			initialCartQuantity.val(0);
			form.submit();
		});

		$('.updateQuantityProduct').on("click", function ()
		{
			var prodid = $(this).attr('id').split("_")
			var form = $('#updateCartForm' + prodid[1]);
			var productCode = form.find('input[name=productCode]').val(); 
			var initialCartQuantity = form.find('input[name=initialQuantity]').val();
			var newCartQuantity = form.find('input[name=quantity]').val();
			var cartData = form.data("cart");

			if(initialCartQuantity != newCartQuantity)
			{
				ACC.track.trackUpdateCart(productCode, initialCartQuantity, newCartQuantity,cartData);
				form.submit();
			}

		});
		$('.cart-tab').on("click", function() {
			$('.saved-tab.selected').removeClass('selected');
			$(this).addClass('selected');
			$('.saved.active').removeClass('active');
			$('.cart').addClass('active');
			$('.cart-content').addClass('selected');
			$('.cart-content-empty').addClass('selected');
			$('.cart_blue_box').removeClass('hide');
			$('.cart_red_box').removeClass('hide');
			$("#tab_selected").text('cart');
			$('.savedTab').addClass('hide');
			var url = new URL(document.URL);
			var search_params = url.searchParams;
			search_params.set("tabType","cart");
			url.search = search_params.toString();
			window.history.pushState({},null,url);
			window.location = url;
   
		});
		$('.saved-tab').on("click", function() {
			$('.cart-tab.selected').removeClass('selected');
			$(this).addClass('selected');
			$('.cart.active').removeClass('active');
			$('.saved').addClass('active');
			$('.cart-content').removeClass('selected');
			$('.cart-content-empty').removeClass('selected');
			$('.cart_blue_box').addClass('hide');
			$('.cart_red_box').addClass('hide');
			$("#tab_selected").text('saved');
			$('.savedTab').removeClass('hide');
			var url = new URL(document.URL);
			var search_params = url.searchParams;
			search_params.set("tabType","saved");
			url.search = search_params.toString();
			window.history.pushState({},null,url);
			window.location = url;
		});
		$('.approval-detail').on("click", function() {
			$('.approval-details-contents').toggleClass('active');
			$('.approval-detail').toggleClass('active');
		});
		$('.cart-blue-box .fa-info-circle').on("click", function ()
		{
			$(".tooltip-blue").toggleClass("active");
		});
		$('.cart-blue-box .fa-times').on("click", function ()
		{
			$(".tooltip-blue").toggleClass("active");
		});
		$('.cart-green-box .fa-info-circle').on("click", function ()
		{
			$(".tooltip-green").toggleClass("active");
		});
		$('.cart-green-box .fa-times').on("click", function ()
		{
			$(".tooltip-green").toggleClass("active");
		});
		$('.cart-red-box .fa-info-circle').on("click", function ()
		{
			$(".tooltip-red").toggleClass("active");
		});
		$('.cart-red-box .fa-times').on("click", function ()
		{
			$(".tooltip-red").toggleClass("active");
		});
	}
}

$(document).ready(function ()
{
	ACC.cartremoveitem.bindAll();
	document.cookie = "myCartRedirectURLCookie=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
	if($("#declined_user").length) {
		$(".homepage-miniButterBar").hide();
	}
	checkEaProductAvailability();
});

function checkEaProductAvailability() {
	$.each($('.ea-oos-entry'), function(index){
		if ($(this).find('.isExtendedAisle').val() === 'true') {
			let entryNumber = $(this).find('.eaCartEntryNumber').val();
			let productRMS = $(this).find('.productNumber').val();
			let storeNumber = $(this).find('.storeNumber').val();
			let eaDeliveryBlockTime = $(this).find('.eaDeliveryBlockTime').val();
			let eaDeliveryDateFromCookie = $(this).find('.eaDeliveryDate').val();
			let cookieName = "ea-delivery-" + productRMS + "-" + storeNumber;
			if (eaDeliveryDateFromCookie != '' && eaDeliveryDateFromCookie != undefined) {
				return;
			}
			$.ajax({
				url: ACC.config.encodedContextPath + '/cart/check-ea-product-availability?entryNumber=' + entryNumber,
				type: 'GET',
				async: true,
				dataType: 'json',
				success: function(data) {
					if(data != null){
						displayEADeliveryDetails(data, entryNumber);
					} else {
						displayEAasOOS(entryNumber);										
					}
				},
				error: function() {
					displayEAasOOS(entryNumber);
				},
				complete: function() {}
			});
		}
	})
}

function getDeliveryFromCookies(cookieName, entryNumber) {	
	var deliveryDetails = getCookieByName(cookieName);
	if (deliveryDetails != null && deliveryDetails != "") {
		displayEADeliveryDetails(deliveryDetails, entryNumber);
		return true;
	}		
	return false;
}

function displayEAasOOS(entryNumber) {
	//show OOS entry and remove in-stock entry from Page
	$('.ea-oos-entry-' + entryNumber).removeClass('hide');
	$('.out-of-stock').removeClass('hide');
	//remove in-stock entry
	$('.cart-entry-' + entryNumber).remove();
	//change count and hide in-stock label if no product present
	var instockCounter = parseInt($('#instockCounter').val())-1;
	var oosCounter = parseInt($('#oosCounter').val())+1;
	if (instockCounter < 1) {
		$('.instock').addClass('hide');
	} else {
		$('#instockDisplayCount').text('(' + instockCounter + ')');
	}
	$('#oosDisplayCount').text('(' + oosCounter + ')');	
}

function displayEADeliveryDetails(data, entryNumber) {
	$('#est-delivery-date-' + entryNumber).removeClass('cart-loading').text(data);	
	//remove OOS hidden entry from DOM
	$('.ea-oos-entry-' + entryNumber).remove();
	//hide OOS label if no product present and no change for in stock count
	var oosCounter = parseInt($('#oosCounter').val())-1;
	if (oosCounter < 1) {
		$('.out-of-stock').addClass('hide');
	}	
}
