var currentPaymentMethod = {
    payMethodId: "",
    autopay: false
};
var paymentMethodCounter = $('input[name=selectedpayment]:radio').length;
var delBtnClass;
var onetimePayment = false;
var paymentMethods = {}, vantivResponse, savePaymentMethod, creditCardErrorId;
var selectedPaymentMethodObjCC = null;
var deviceData; var paymentMethodEle;

$(document).ready(function(){
	if(RAC.cordovaWrapper){
		venmoButtonWrapper();
	}
//bank Account
if($('#page-name').val() == "racAddBankACHPage") {
	var type = $('#checkingType').val();
	if($('#checkingType').val()){
		$("input:radio[value=" + type + "]").prop("checked", true );
	}
	
	if($('#approvalResponse').val() == "2"){
       $('.addBankAccountPopup1').modal('hide');
       $("#bankDeclineError").modal({backdrop: 'dynamic', keyboard: true});
	}
	else if($('#approvalResponse').val() == "3"){
		$('.addBankAccountPopup1').modal('hide');
	    $("#bankRetryError").modal({backdrop: 'dynamic', keyboard: true});
	}
	else if($('#approvalResponse').val() == "4"){
		 $('.addBankAccountPopup1').modal('hide');
	     $('#serviceErrorPopup').modal('show');
	}
	else if($('#approvalResponse').val() == "10"){
		 RAC.loadingSpinnerHide();
		 $('#technicalDifficultiesPopup').modal('show');
	}
}
	
if(($('#page-name').val() == "racAddCCPage") ){
	if($('#approvalResponse').val() == "2"){
	    $('#cardDeclineError').modal('show');
	}
	else if($('#approvalResponse').val() == "3"){
		$('#cardRetryError').modal('show');
	}
	else if($('#approvalResponse').val() == "4"){
		$('#serviceErrorPopup').modal('show');
	}
	else if($('#approvalResponse').val() == "10"){
		RAC.loadingSpinnerHide();
		$('#technicalDifficultiesPopup').modal('show');
	}
}

if ($("#OnetimePaymentMethodData").val()){
	$("#payMethodRadio-0").prop('checked', true).trigger('change');
}

$(".achPaymentBackButton").on("click", function(){
	location.href = ACCS.config.encodedContextPath + "/myaccount/paymentmethods";
});

$(".achPaymentBackCCButton").on("click", function(){
var onetimeCC = $("#onetime").val();
if ( onetimeCC == "true" )
	{
	location.href = ACCS.config.encodedContextPath + "/myaccount/make-one-time-payment-select-payment-method";
	}
else
	{
	location.href = ACCS.config.encodedContextPath + "/myaccount/paymentmethods";
		}
});
	
$(".keepmesignedin-btn").click(function(){
	sessionTimeoutCall().done(function(data, textStatus) {
        if(checkLoginSessionTimeout(data)){
            window.location.href = "/";
        }
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
        $('#serviceErrorPopup').modal('show');
    });
});

        /** Start of Radio Button :Credit Card and Bank  **/
    $(".pseudo-radio-button").on("click", function() {
        $(this).parent('.radio-container').parent(".addPaymentRadio").find('.application-radio-button').each(function() {
            $(this).parent('.radio-container').parent(".addPaymentRadio").find('a.pseudo-radio-button').removeClass('radio-selected');
            $(this).prop('checked', false);
        });
        $(this).next('input[type="radio"]').prop('checked', true);
        $(this).addClass('radio-selected');
    });

    savePaymentMethod = $('#savePaymentMethod').val();
    if(savePaymentMethod == "false"){
        $("#nameAddrInfoPopupSave").val("Continue");
        $("#addBankAccountSave").val("Continue");
    }

    pageName = $('#page-name').val();
	if (pageName == "racAddCCPage" || pageName == "racReviewPaymentPage" || pageName == "racPaymentMethodsPage" || pageName == "racReviewAutopayPage") {
	 showVantivSection();
	  $("#creditcardInfoPopup").show();
	  $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      RAC.loadingSpinnerHide();
	}
    $('[data-toggle="tooltip"]').tooltip();

    $('.modal').on('show.bs.modal', function (event) {
        $('[data-toggle="tooltip"]').tooltip('hide');
    });

    //Left Nav href update
    function updateSideMenuUrl(){
        var $listItem = $('#side-menu li a');
        if(window.location.pathname.indexOf("myagreements")>=0){
            $listItem.filter(function() { return $.text([this]) === 'Agreements'; }).addClass("active").attr("href", window.location.pathname);
            if($('.table.agreements-table').length===0){
                $(".welcome-block .contact-us").remove();
            }
        }
        else if(window.location.pathname.indexOf("paymentmethods")>=0){
            $listItem.filter(function() { return $.text([this]) === 'Payment Methods'; }).addClass("active").attr("href", window.location.pathname);
            $(".welcome-block .contact-us").remove();
        }
    }

    updateSideMenuUrl();

    function checkLoginSessionTimeout(response){
        var data = response?JSON.parse(response):null;
        return data && data.sessionTimeout? true: false;
    }


    // AutoPay Modal Close
    $('#deleteCancelBtn').on('click', function(){
        $('#deletePopup').modal('hide');
    });

	
    $("#deletePopupBtn").on("click", deletePaymentMethod);

    $(".addPaymentMethod").on("click", checkPaymentAllowed);

    $("#addPaymentContinue").on("click", addPaymentMethod);
    
    $(".manageAutoPayBtn").unbind().click(function(){
    	enrollClickNow(); 
    });
    
    $(".manageAutoPayBtn1").unbind().click(function(){
    	enrollClickNow(); 
    });
    
    $("#manageAutoPayBtnSucs").unbind().click(function(){
    	enrollClickNow(); 
    });

    $('#autoPayCloseBtn').on('click', function(){
        $('#autoPayPopup').modal('hide');
    });

    $('select').on('show.bs.select', function (e) {
        $(".bootstrap-select.btn-group .dropdown-menu li.disabled a").removeAttr("href");
    });


	
	$("#bankAccApartment").blur(function() {
		var aptCheck = $('#bankAccApartment').val();
		
			if (aptCheck.trim() === "") {
	          $('#bankAccApartment').parent().removeClass('has-success');
	          $('#bankAccApartment').parent().find('span').removeClass('check-circle');
	         
	        } else {
	          $('#bankAccApartment').parent().addClass('has-success');
        		$('#bankAccApartment').parent().find('span').addClass('check-circle');
	        }
	     });
	     $("#nameAddrApartment").blur(function() {
		var aptCheck = $('#nameAddrApartment').val();
		
			if (aptCheck.trim() === "") {
	          $('#nameAddrApartment').parent().removeClass('has-success');
	          $('#nameAddrApartment').parent().find('span').removeClass('check-circle');
	         
	        } else {
	          $('#nameAddrApartment').parent().addClass('has-success');
        		$('#nameAddrApartment').parent().find('span').addClass('check-circle');
	        }
	     });
	    
	     $("#secureApartmenntNbr").blur(function() {
		var aptCheck = $('#secureApartmenntNbr').val();
		
			if (aptCheck.trim() === "") {
	          $('#secureApartmenntNbr').parent().removeClass('has-success');
	          $('#secureApartmenntNbr').parent().find('span').removeClass('check-circle');
	         
	        } else {
	          $('#secureApartmenntNbr').parent().addClass('has-success');
        		$('#secureApartmenntNbr').parent().find('span').addClass('check-circle');
	        }
	     });
    function manageAutoPayHandler(){
        var buttonElem =  $(this);
        buttonElem.attr("disabled", true);
        sessionTimeoutCall()
            .done(function(data, textStatus) {
                if(!checkLoginSessionTimeout(data)){
                    if($('#autoPayAllowed').val() == 'true'){
                        window.location.href = ACC.config.encodedContextPath + "/myaccount/manageautopay";
                    }
                    else{
                        $('#autoPayPopup').modal('show');
                    }
                }
                else{
                    window.location.href = "/";
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                $('#serviceErrorPopup').modal('show');
            })
            .always(function(){
                buttonElem.attr("disabled", false);
            });
    }

    function resetValidations(elements){
        for(var i=0; i<elements.length; i++){
            $("#"+elements[i]).parsley().reset();
        }
    }

    function setPaymentMethodRadio(element){
        var inputElem = $('#'+element);
        inputElem.parent('.radio-container').parent(".addPaymentRadio").find('.application-radio-button').each(function() {
            $(this).parent('.radio-container').parent(".addPaymentRadio").find('a.pseudo-radio-button').removeClass('radio-selected');
            $(this).prop('checked', false);
        });
        inputElem.attr("checked", true);
        inputElem.prev('a.pseudo-radio-button').addClass('radio-selected');
    }

    function checkPaymentAllowed(){
        var buttonElem =  $(this);
        buttonElem.attr("disabled", true);
        sessionTimeoutCall()
            .done(function(data, textStatus) {
                if(!checkLoginSessionTimeout(data)){
                    var isACHAllowed = $("#achAllowed").val(), noOfPaymentMethods = $(".payments-table>tbody>tr").length;
                    if(noOfPaymentMethods < 3){
                        if(isACHAllowed=="true"){
                            setPaymentMethodRadio("bankAccountRadio");
                            $('#addPaymentMethodPopup').modal('show');
                        }
                        else{
                            setPaymentMethodRadio("creditCardRadio");
							showVantivSection();
                            $('#creditcardInfoPopup').modal('show');
                            securevantiv();
                        }
                    }
                    else{
                        $('#paymentPopup').modal('show');
                    }
                }
                else{
                    window.location.href = "/";
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                $('#serviceErrorPopup').modal('show');
            })
            .always(function(){
                buttonElem.attr("disabled", false);
            });
        //To DO - add handler

    }

    function addPaymentMethod(){
        var buttonElem =  $(this);
        buttonElem.attr("disabled", true);
        sessionTimeoutCall()
            .done(function(data, textStatus) {
                if(!checkLoginSessionTimeout(data)){
                    //To DO - add handler
                    $('#addPaymentMethodPopup').modal('hide');
                    if($('#bankAccountRadio').is(':checked')) {
                        $('#addBankAccountPopup').modal('show');
                    }
                    else{
						showVantivSection();
                        $('#creditcardInfoPopup').modal('show');
                    }
                }
                else{
                    window.location.href = "/";
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                $('#serviceErrorPopup').modal('show');
            })
            .always(function(){
                buttonElem.attr("disabled", false);
            });
    }

    function deletePaymentMethod(){
        var buttonElem =  $(this);
        buttonElem.attr("disabled", true);
        var thisCardType = delBtnClass;
        /*Delete Request Object*/
        var deletePaymentReqObj = {
                                  payMethodId: currentPaymentMethod.payMethodId
                                  };

        sessionTimeoutCall()
            .done(function(data, textStatus) {
                if(!checkLoginSessionTimeout(data)){
                    if( currentPaymentMethod.payMethodId != "") {
                        /*Delete AJAX Call*/
                        $.ajax({
                            url: '/deletepaymentmethod?paymentMethodId=' + currentPaymentMethod.payMethodId,
                            type: 'GET',
                            success: function (data) {
                                var responseData = data ? JSON.parse(data) : null;
                                $('#deletePopup').modal('hide');
                                if (responseData && responseData.status == '200') {
                                    if ($(".payments-table>tbody>tr").length == 1) {
                                        $(".payments-table").remove();
                                        $(".payment-block-" + currentPaymentMethod.payMethodId).addClass("hidden");
                                        $(".thanks-section").removeClass("hidden");
                                        $(".agreementDescription").removeClass("hidden");

                                    } else {
                                        $(".payment-block-" + currentPaymentMethod.payMethodId).addClass("hidden");
                                    }
                                } else {
                                    if (thisCardType.toLowerCase().indexOf("paypal") >= 0 || thisCardType.toLowerCase().indexOf("venmo") >= 0) {
                                        RAC.loadingSpinnerHide();
                                        $('#technicalDifficultiesPopup').modal('show');
                                    } else {
                                        RAC.loadingSpinnerHide();
                                        $('#serviceErrorPopup').modal('show');
                                    }
                                }
                            },
                            error: function (error) {
                                RAC.loadingSpinnerHide();
                                $('#deletePopup').modal('hide');
                                $('#serviceErrorPopup').modal('show');
                            },
                            complete: function () {
                                RAC.loadingSpinnerHide();
                                $('body').removeClass('modal-open');
                                $('.modal-backdrop').remove();
                                $('body').css("padding-right", "0px");
                                buttonElem.attr("disabled", false);
                                let numberOfCards = $(".saved-payment-method .payment-methods:not(.hidden)").length;
                                let savedPaymentHeader = $(".in-empty-hide");
                                if (numberOfCards === 0) {
                                    savedPaymentHeader.addClass("hide");
                                } else {
                                    savedPaymentHeader.removeClass("hide");
                                }
                            }
                        });
                    } else {
                        if (thisCardType.toLowerCase().indexOf("paypal") >= 0 || thisCardType.toLowerCase().indexOf("venmo") >= 0) {
                            RAC.loadingSpinnerHide();
                            $('#deletePopup').modal('hide');
                            $('#technicalDifficultiesPopup').modal('show');
                            buttonElem.attr("disabled", false);
                        } else {
                            RAC.loadingSpinnerHide();
                            $('#deletePopup').modal('hide');
                            $('#serviceErrorPopup').modal('show');
                            buttonElem.attr("disabled", false);
                        }
                    }
                }
                else{
                    window.location.href = "/";
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                $('#serviceErrorPopup').modal('show');
            })
            .always(function(){
                handleSpinner();
                RAC.loadingSpinnerShow();
            });
    }

    function checkAutoPayEnrolled(){
        currentPaymentMethod.$row = $(this).closest('tr');
        currentPaymentMethod.payMethodId = $(this).data('payment-method-id');
        currentPaymentMethod.autopay = $(this).data('autopay');
        delBtnClass = $(this).attr('class');
        sessionTimeoutCall()
            .done(function(data, textStatus) {
                if(!checkLoginSessionTimeout(data)){
                    currentPaymentMethod.autopay ? $('#unenrollPopup').modal('show') : $('#deletePopup').modal('show');
                }
                else{
                    window.location.href = "/";
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                $('#serviceErrorPopup').modal('show');
            })
            .always(function(){
            });
    }

    $('.agreementSection').on('click', '.deletePaymentMethod', checkAutoPayEnrolled);

    $('#creditCardContinue').on('click', function(e) {
        $(this).css('pointer-events','none');
        sessionTimeoutCall()
            .done(function(data, textStatus) {
                if(!checkLoginSessionTimeout(data)){
                    $(this).attr("disabled", true);
                    $("#payForm").submit();
                }
                else{
                    window.location.href = "/";
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                $('#serviceErrorPopup').modal('show');
            })
            .always(function(){

            });
    });

    $('#nameAddrInfoPopup').on('show.bs.modal', function (event) {
        $("#nameAddrInfoPopup input[type='text']").val('');
        $("#nameAddrInfoPopup input[type='checkbox']").attr('checked', false);
        $('#nameAddrselectState').val('');
        $('#nameAddrselectState').selectpicker('refresh');
        $("*[data-id='nameAddrselectState']").removeClass("border-red");
        resetValidations(["nameAddrNameOnCard", "nameAddrBillingAddress", "nameAddrselectState", "nameAddrZipcode", "nameAddrCity"]);
    });


	function showVantivSection() {
		$('#vantivSection').removeClass("hidden");
        $('#payframe').remove();
        $('#payForm').append('<div id="payframe"></div>');
        let addAccFormSubmit = false;
        var payframeClientCallback = function(response) {
            vantivResponse = response;
            //log vantiv response on Kibana
            var responseMessageLog = {
                              'index':ACCS.config.indexx,
                              'environment': ACCS.config.env,
                              'vantiv-guid' : ACCS.config.custGUID,
                              'vantiv-webSession-guid' : document.getElementById("hfHybWebSessionGUID").value,
                              'responseMsg': response
                              
                  };
               if(ACCS.config.url) {
                  $.ajax({
                    url: ACCS.config.url,
                    method: "post",
                    dataType: "json",
                    contentType: 'application/json',
                    data : JSON.stringify(responseMessageLog),
                    headers: { "X-ApiKey": ACCS.config.apikey },
                    crossDomain: true,
                    success: function(data) {
                    },
                    error: function(error){
	                    console.log(error);
                    },
                    complete: function(){
    	                $("#creditCardContinue").css('pointer-events','');
                    }

                  });
               }

            $("#nameAddrInfoPopupSave").attr("disabled", false);
            $("#submitNewCC").attr("disabled", false);
            if (response.timeout) {
                $("#creditCardContinue").css('pointer-events','');
                $('#vantivDownError').modal('show');
                $('#vantivSection').addClass("hidden");
                $('#vantivDownErrorSecure').show();
            } else if (response.response >= 871
                && response.response <= 874
                || response.response >= 881
                && response.response <= 883
                || response.response == 876
                || response.response == 886) {
                $("#creditCardContinue").css('pointer-events','');
                $('#cardInfoError').modal('show');
                $('#cardInfoErrorModal').modal('show');
                //$("#vantivError").show();
            } else if (response.response == 875
                || response.response == 884
                || response.response == 885
                || response.response == 889) {
                $("#creditCardContinue").css('pointer-events','');
                $('#cardInfoError').modal('show');
                $('#cardInfoErrorModal').modal('show');
                $("#vantivError").show();
            } else {
                $("#creditCardContinue").css('pointer-events','none');
                $('#creditcardInfoPopup').modal('hide');
                $('#nameAddrInfoPopup').modal('show');
                $("#vantivError").hide();
                $('#vantivDownErrorSecure').hide();
                handleSpinner();
                RAC.loadingSpinnerShow();
                if (isFalse(addAccFormSubmit)) {
                    paymentMethods.saveCCPayment($("#nameAddrInfoPopupSave"), vantivResponse);
                    addAccFormSubmit = true;
                }
            }
        };
        var configure = {
            "paypageId": document.getElementById("request$paypageId").value, //"uwrjGM3KiuKcqyAs",
            "style": "vantivcss10",
            "height": "220px",
            "reportGroup": document.getElementById("request$reportGroup").value, //"ecomm",
            "timeout": document.getElementById("request$timeout").value, //"15000",
            "div": "payframe",
            "callback": payframeClientCallback,
            "showCvv": true,
            "enhancedUxFeatures": {
                "inlineFieldValidations": true,
                "expDateValidation": true,
                "numericInputsOnly": true,
                "enhancedUxVersion": 2
            },
            "customErrorMessages": {
                "871": ACC.errorCode871Txt,
                "872": ACC.errorCode872Txt,
                "873": ACC.errorCode873Txt,
                "874": ACC.errorCode874Txt,
                "876": ACC.errorCode876Txt,
                "881": ACC.errorCode881Txt,
                "882": ACC.errorCode882Txt,
                "883": ACC.errorCode883Txt,
                "886-month": ACC.errorCode886MonthTxt,
                "886-year": ACC.errorCode886YearTxt,
                "886": ACC.errorCode886Txt
            },
            "months": {
                "1": "01",
                "2": "02",
                "3": "03",
                "4": "04",
                "5": "05",
                "6": "06",
                "7": "07",
                "8": "08",
                "9": "09",
                "10": "10",
                "11": "11",
                "12": "12"
            },
            "numYears": 10,
            "tooltipText": ACC.cvvTooltipTxt,
            "tabIndex": {
                "accountNumber": 1,
                "expMonth": 2,
                "expYear": 3,
                "cvv": 4
            },
            "placeholderText": {
                "cvv": "",
                "accountNumber": ""
            }
        };
        if (typeof EprotectIframeClient  === 'undefined') {
            // This means we couldn't download the payframe-client
            // javascript library
            //alert("Couldn't download payframe-client javascript");
            $('#vantivDownError').modal('show');
            $('#vantivSection').addClass("hidden");
        }
        else{
            var payframeClient = new EprotectIframeClient(configure);
            payframeClient.autoAdjustHeight();
            document.getElementById("payForm").onsubmit = function() {
                var message = {
                    "id": document.getElementById("request$merchantTxnId").value, //"9999199831141",
                    "orderId": document.getElementById("request$orderId").value //9999199831141
                };
                payframeClient.getPaypageRegistrationId(message);
                return false;
            };
        }
        

    }


    paymentMethods.saveCCPayment = function (elem, response){
        var ccReqObj, bankAccReqObj;
        if(response){
            ccReqObj = {
                savePaymentMethod:savePaymentMethod,
                nameOnCard:$("#nameAddrNameOnCard").val(),
                externalOrderId: document.getElementById("request$orderId").value,
                address2:$("#nameAddrApartment").val(),//Apartment Address
                address1:$("#nameAddrBillingAddress").val(),//Home Address
                city:$("#nameAddrCity").val(),
                PhoneNumber: "",
                postalCode:$("#nameAddrZipcode").val(),
                type:response.type,
                lastDigits:response.lastFour,
                state:$("#nameAddrselectState").val(),
                cardExpiration:response.expMonth + response.expYear,
                paymentRegistrationId: response.paypageRegistrationId,
                bankIdNumber: response.bin//First 6 Digits
            };
        }
        else{
            bankAccReqObj = {
                savePaymentMethod:savePaymentMethod,
                nameOnCard:$("#bankAccNameOnAccount").val(),
                externalOrderId: document.getElementById("request$orderId").value,
                phoneNumber:$("#bankAccContactNumber").inputmask("unmaskedvalue"),
                address2:$("#bankAccApartment").val(),//Apartment Address
                address1:$("#bankAccBillingAddress").val(),//Home Address
                city:$("#bankAccCity").val(),
                postalCode:$("#bankAccZipcode").val(),
                //type:$("#bankAccSelectAccType").val(),
                type:$('input[name="radio"]:checked').val(),
                routingNumber:$("#bankAccRoutingNumber").val(),
                state:$("#bankAccState").val(),
                bankIdNumber: $("#bankAccountNumber").val(),
                lastDigits:$("#bankAccountNumber").val().slice(-3)
            }
        }

        sessionTimeoutCall()
            .done(function(data, textStatus) {
                if(!checkLoginSessionTimeout(data)){
                    elem.attr("disabled", true);
                    if(response!==null){
                    var form = $('#creditCardForm');
                    $("#externalOrderId").val(document.getElementById("request$orderId").value);
                    $("#creditCardForm #type").val(response.type);
                    $("#creditCardForm #lastDigits").val(response.lastFour);
                    $("#creditCardForm #cardExpiration").val(response.expMonth + response.expYear);
                    $("#creditCardForm #paymentRegistrationId").val(response.paypageRegistrationId);
                    $("#creditCardForm #bankIdNumber").val(response.bin);
                    $("#creditCardForm #savePaymentMethod").val(savePaymentMethod);
                    if(pageName == "racPaymentMethodsPage") {
                        let nameOnCard = $("#firstNameOnCard").val() +' '+ $("#lastNameOnCard").val();
                        $("#nameAddrNameOnCard").val(nameOnCard);
                    }
                    form.submit();
                    } else {
                        var form = $('#bankACHForm');
                        $("#bankACHForm #externalOrderId").val(document.getElementById("request$orderId").value);
                        $("#bankACHForm #type").val($('#bankACHForm input[name="radio"]:checked').val());
                        $("#bankACHForm #lastDigits").val($("#bankAccountNumber").val().slice(-3));
                        $("#bankACHForm #phoneNumber").val($("#bankAccContactNumber").inputmask("unmaskedvalue"));
                        if (pageName == "racPaymentMethodsPage") {
                            let nameOnCard = $("#bankAccFirstName").val() +' '+ $("#bankAccLastName").val();
                            $("#bankAccNameOnAccount").val(nameOnCard);
                        }
                        form.submit();
                        handleSpinner();
                        RAC.loadingSpinnerShow();
                    }
                }
                else{
                    window.location.href = "/";
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                elem.css('pointer-events','');
                $('#serviceErrorPopup').modal('show');
            })
            .always(function(){
            });
    }
//Handle timeout AJAX error
    $( document ).ajaxError(function( event, jqxhr, settings, thrownError ) {
        if ( jqxhr.statusText === "timeout" ) {
            console.log("timeout error");
            $(".modal").modal('hide');
            $('#serviceErrorPopup').modal('show');
        }
    });
    $('#migratePaymentMethod').modal({keyboard : false,backdrop : 'static'});
    if($("#addPaymentMethodFlag").val() === true || $("#addPaymentMethodFlag").val() == "true"){
        var checkStorelocator = setInterval(function() {
            if($("div#qtip-0").length > 0 || $("div#qtip-1").length > 0){
                $("div#qtip-0, div#qtip-1, #qtip-overlay").hide();
                $("#mobileNavMapButton").removeClass("open");
                clearInterval(checkStorelocator);
            }
        }, 100);
        $("#migratePaymentMethod a.btn").on("contextmenu", function(){
        	return false;
        });
    }
    
    $("#checkBillingAddress").on('change', function(){
    	if($(this).is(':checked')){
    		$("#nameAddrBillingAddress, #nameAddrApartment, #nameAddrCity, #nameAddrselectState, #nameAddrZipcode, #bankAccBillingAddress, #bankAccApartment, #bankAccCity, #bankAccState, #bankAccZipcode").val("");
    		$("#nameAddrBillingAddress").val($("#achHomeAddress").val()).attr('disabled','disabled');
    		$("#nameAddrApartment").val($("#achApartmentNumber").val()).attr('disabled','disabled');
    		$("#nameAddrCity").val($("#achCity").val()).attr('disabled','disabled');
    		$("#nameAddrselectState").val($('#achState').val()).attr('disabled','disabled');
    		$("#nameAddrselectState").selectpicker('refresh');
    		$("#nameAddrZipcode").val($("#achZipCode").val()).attr('disabled','disabled');
    		$( ".creditValid span ul li" ).empty();
			$( ".creditValid span ul" ).css('list-style','none');
			$(".creditValid").removeClass('has-error');
			let accBillingAddress = $("#achHomeAddress").val();
			let accAptNo = $("#achApartmentNumber").val();
			let accCityName = $("#achCity").val();
			let accStateName = $("#achState").val();
			let accZipCode = $("#achZipCode").val();
			let emptyAptNo = accAptNo ? `${accAptNo}</br>` : '';
			$(".complete-payment-box .address-box-wrap .address-span").html(`${accBillingAddress}</br> ${emptyAptNo} ${accCityName}, ${accStateName} ${accZipCode}`);
			$(".complete-payment-box .address-box-wrap").removeClass('d-none');
			$(".complete-payment-box .acc-address-fields").hide();
    	} else {
    		$("#nameAddrBillingAddress").removeAttr('disabled');
    		$("#nameAddrApartment").removeAttr('disabled');
    		$("#nameAddrCity").removeAttr('disabled');
    		$("#nameAddrselectState").removeAttr('disabled');
    		$("#nameAddrselectState").selectpicker('refresh');
    		$("#nameAddrZipcode").removeAttr('disabled');
    		$("#nameAddrBillingAddress").val("");
    		$("#nameAddrApartment").val("");
    		$("#nameAddrCity").val("");
    		$("#nameAddrZipcode").val("");
    		$(".adrCreditCheck").removeClass('has-error');
			$(".adrCreditCheck").removeClass('has-success');
			$(".adrCreditCheck .successchk").removeClass('check-circle');
    		$('.creditValid .filter-option').text("Select State");
			$('.creditValid #nameAddrselectState option:eq(0)').attr('selected','selected'); 
    		$(".complete-payment-box .address-box-wrap").addClass('d-none');
    		$(".complete-payment-box .acc-address-fields").show();
    	}
    });
    $("#checkBankBillingAddress").on('change', function(){
    	if($(this).is(':checked')){
    		$("#bankAccBillingAddress").val($("#achHomeAddress").val()).attr('disabled','disabled');
    		$("#bankAccApartment").val($("#achApartmentNumber").val()).attr('disabled','disabled');
    		$("#bankAccCity").val($("#achCity").val()).attr('disabled','disabled');
    		$("#bankAccState").val($('#achState').val()).attr('disabled','disabled');
    		$("#bankAccState").selectpicker('refresh');
    		$("#bankAccZipcode").val($("#achZipCode").val()).attr('disabled','disabled');
    		var billingAddressInstance = $('#bankAccBillingAddress').parsley();
    		var appartmentInstance = $('#bankAccApartment').parsley();
			var cityInstance = $('#bankAccCity').parsley();
			var selectStateInstance = $('#bankAccState').parsley();
			var zipCodeInstance = $('#bankAccZipcode').parsley();
			billingAddressInstance.validate();
    		cityInstance.validate();
    		selectStateInstance.validate();
    		zipCodeInstance.validate();
    		if(selectStateInstance.isValid()){
    			$("#bankAccState").siblings("button").removeClass("border-red");
    		}
            let accBillingAddress = $("#achHomeAddress").val();
            let accAptNo = $("#achApartmentNumber").val();
            let accCityName = $("#achCity").val();
            let accStateName = $("#achState").val();
            let accZipCode = $("#achZipCode").val();
            let emptyAptNo = accAptNo ? `${accAptNo}</br>` : '';
            $(".saved-payment-bank-account .address-box-wrap .address-span").html(`${accBillingAddress}</br> ${emptyAptNo} ${accCityName}, ${accStateName} ${accZipCode}`);
            $(".saved-payment-bank-account .address-box-wrap").removeClass('d-none');
            $(".saved-payment-bank-account .acc-address-fields").hide();
    	} else {
    		$("#bankAccBillingAddress").removeAttr('disabled');
    		$("#bankAccApartment").removeAttr('disabled');
    		$("#bankAccCity").removeAttr('disabled');
    		$("#bankAccState").removeAttr('disabled');
    		$("#bankAccState").selectpicker('refresh');
    		$("#bankAccZipcode").removeAttr('disabled');
    		$("#bankAccBillingAddress").val("");
    		$("#bankAccApartment").val("");
    		$("#bankAccCity").val("");
    		$("#bankAccZipcode").val("");
    		$(".adrCheck").removeClass('has-error');
			$(".adrCheck").removeClass('has-success');
			$(".adrCheck .successchk").removeClass('check-circle');
    		$('.bankValid .filter-option').text("Select State");
			$('.bankValid #bankAccState option:eq(0)').attr('selected','selected');
            $(".saved-payment-bank-account .address-box-wrap").addClass('d-none');
            $(".saved-payment-bank-account .acc-address-fields").show();
        }
    });
    
    $(document).on('click', '.moreLink' , function() {
    	
    			$(this).addClass('hide');
    			$(this).next('.lessLink').removeClass('hide');
    			$(this).parent('div.row').next('div.row.paymentInfo').removeClass('hide');

    		});

    $(document).on('click', '.lessLink' , function() {
    	$(this).parent('div.row').next('div.row.paymentInfo').addClass('hide');
		$(this).addClass('hide');
		$(this).prev('.moreLink').removeClass('hide');
		

	});
    
    $(document).on('click','.bankModal', function(){
  	  setTheDeafultBankModal();
  	  $("#addBankAccountPopup1").modal('show');
    });
    
    $(document).on('click','.creditModal', function(){
  	  showVantivSection();
	  $("#creditcardInfoPopup").modal('show');
    }); 

    var payPalVenmoEnabled = $('#paypalVenmoEnabled').val();
    if (payPalVenmoEnabled) {
	     var braintreeMerchantAccountId = $('#braintreeMerchantAccountId').val();
	     var clientToken = $('#clientToken').val();
	     enablePaypalVenmo(braintreeMerchantAccountId,clientToken);
     }

    $('.deletePaymentMethod').on('click', function() {
        // Find the closest .paymentMethod-tile parent
        var parentDiv = $(this).closest('.payment-method-tile');
        var cardName = parentDiv.find('.cardValue-type').text().trim();
        var cardValue = parentDiv.find('.cardValue-number').html();
        // Check if the card image exists and get the source if it does
        var cardImage = parentDiv.find('.card-image');
        var cardImageSrc = cardImage.length > 0 ? cardImage.attr('src') : '';

        // Clear the image source if it does not exist
        if (isNullUndefined(cardImageSrc)) {
            $('.pay-card-image').hide();
        } else {
            // Append the image only if it exists
            $('.pay-card-image').show();
            $('.pay-card-image img').attr('src', cardImageSrc);
            if (parentDiv.hasClass('saved-cashapp')) {
				$('.pay-card-image img').css("width", "20");
            }
        }

        $(".selectedCardName").text(cardName);
        $(".selectedCardValue").html(cardValue);

        if (isNullUndefined(cardName)) {
            $(".selectedCardName").removeClass("paddingR5");
            $(".selectedCardValue").removeClass("dpinlineflex align-center");
        } else {
            $(".selectedCardName").addClass("paddingR5");
            $(".selectedCardValue").addClass("dpinlineflex align-center");
        }
    });
    $('.delete-payment-method').on('hidden.bs.modal', function() {
		$(".delete-payment-method .selectedCardName").text('');
		$(".delete-payment-method .selectedCardValue").html('');
		$('.delete-payment-method .pay-card-image img').css("width", "");
	});
});
function enablePaypalVenmo(braintreeMerchantAccountId,clientToken ) {
	$("#paypal-button").html("");  // making div empty to solve multiple button issues
	var venmoSuccess = getUrlParameter('venmoSuccess');
	if (venmoSuccess =="1")
		{
	var username = getUrlParameter('username');
	var paymentMethodNonce = getUrlParameter('paymentMethodNonce');
	var details={
			username:getUrlParameter('username')
	}
	var venmoPayload = {
            nonce: getUrlParameter('paymentMethodNonce'),
            type: 'VenmoAccount',
            details: details
          };
	handleVenmoSuccess(venmoPayload);
		}
	var venmoCancel = getUrlParameter('venmoCancel');
	if (venmoCancel =="1")
	{
		console.log("venmoCancel " + venmoCancel);
	}
	var venmoError = getUrlParameter('venmoError');
	if (venmoError =="1")
	{
		console.log("venmoError " + venmoError);
	}

    var pageName = $('#page-name').val();
	window.RAC = window.RAC || {};
 	RAC.payments = RAC.payments || {};
 	RAC.payments.merchantAccountId= braintreeMerchantAccountId;
 	
 	var braintreeEnvironment = $('#braintreeEnvironment').val();
    var client_token = clientToken;
    var button = document.querySelector('#submit-button');
    var venmoButton = document.getElementById('venmo-button');
    var paypalPayload = '';
 // Create a client.
    braintree.client.create({
      authorization: client_token
    }, function (clientErr, clientInstance) {
      // Stop if there was a problem creating the client.
      // This could happen if there is a network error or if the authorization
      // is invalid.
      if (clientErr) {
        console.error('Error creating client:', clientErr);
        return;
      }
	  
	  braintree.dataCollector.create({
    client: clientInstance,
    paypal: true
  }, function (dataCollectorErr, dataCollectorInstance) {
    if (dataCollectorErr) {
      // Handle error in creation of data collector.
      return;
    }

    // At this point, you should access the deviceData value and provide it
    // to your server, e.g. by injecting it into your form as a hidden input.
    deviceData = dataCollectorInstance.deviceData;
  });

      // Create a PayPal Checkout component.
      braintree.paypalCheckout.create({
        client: clientInstance
      }, function (paypalCheckoutErr, paypalCheckoutInstance) {
 
        // Stop if there was a problem creating PayPal Checkout.
        // This could happen if there was a network error or if it's incorrectly
        // configured.
        if (paypalCheckoutErr) {
          console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
          return;
        }
 
        // Set up PayPal with the checkout.js library
        paypal.Button.render({
          env: braintreeEnvironment , // or 'sandbox'
          funding: {
        	  disallowed: [ paypal.FUNDING.VENMO ]
        	  },
        	  style: {
        	  size: 'large',
        	  color: 'gold',
        	  shape: 'rect',
        	  label: 'paypal',
        	  tagline: 'false'
        	  },
          payment: function () {
            return paypalCheckoutInstance.createPayment({
                flow:'vault'
              // Your PayPal options here. For available options, see
              // http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
            });
          },
 
          onAuthorize: function (data, actions) {
            return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {
            payload.deviceData = deviceData;
				paypalPayload = payload;
				if(payload) {
				paymentMethodCounter++;
				if(pageName == "racReviewPaymentPage") {
					
						var customerName = payload.details.firstName +" " +payload.details.lastName;
						var customerEmail = payload.details.email;
						// remove existing paypal radio button
						$(".changePayment .braintree-paypal-wrapper").remove();

					// Add Paypal radio button 
					var pmType = "PayPal";
					var paymentMethodEle = '<div class="braintree-paypal-wrapper payment-method paypalUserInfo bdr3 border-green border-width-1 dpflex align-center marginT23"><label class="checktest radioColor cardDetail cardDet" id="payMethod-' + paymentMethodCounter + '"><input type="radio" name="selectedpayment" id="payMethodRadio-' + paymentMethodCounter + '" class="braintree-payments"><span class="label-text font13 fontMob12 cardValue display-block ellipsis"><span class="cardValue-number text-lowercase-imp">'+customerEmail+'</span></span><input type="hidden" class="methodObj" value="'+ escape(JSON.stringify(payload)) +'"></label></div>';
					updatePaymentMethodHeader();	
					$(".paypal-det-box").prepend(paymentMethodEle);
					var paypalRadioId = "#payMethodRadio-" + paymentMethodCounter;
					$(paypalRadioId).prop('checked', true).trigger('change');
					$('#paymentMethodErrorSecurePaypal').addClass('hidden');
					if($('#paypal-IPO .tnc').is(':checked')) {
						$('#paypal-IPO').find('#pay-now-btn1').removeClass('btn-disabled');
					}
				}
				if(pageName == "racPaymentMethodsPage") {
					addPayPalOrVenmoPaymentMethod(payload);
				}
					var customerName = payload.details.firstName +" " +payload.details.lastName;
					var customerEmail = payload.details.email;
					$(".payload").attr("paymentmethodid",JSON.stringify(payload));
					$(".paypalUserInfo").css("display","flex"); 
                    $(".paypalUserInfo span:last-child").html(customerEmail);
                    $("#paymentMethodErrorSecurePaypal").addClass("hidden");
                    $('#paypal-IPO .payment-section').find('#secureFormSubmit').removeClass('no-pm-selected');
                    if($('#paypal-IPO .payment-section .securetermsandcond').is(':checked')) {
                    	$('#paypal-IPO .payment-section').find('#secureFormSubmit').removeClass('btn-disabled');
                    }
				}
              // Submit `payload.nonce` to your server.
            });
          },
 
          onCancel: function (data) {
            console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
          },
 
          onError: function (err) {
            console.error('checkout.js error', err);
          }
        }, '#paypal-button').then(function () {
			
          // The PayPal button will be rendered in an html element with the id
          // `paypal-button`. This function will be called when the PayPal button
          // is set up and ready to be used.
 
        	if($(".paypal-button").length > 1) {   // fix for multiple payapl buttons
           		console.log("PayPal button loaded Multiple Times");
           		$( ".paypal-button" ).slice( 0, ($(".paypal-button").length) - 1 ).remove();
           	 }

        	
          
        });
        
      });
 
      var venmoCreateObj = {
              client: clientInstance,
              // Add allowNewBrowserTab: false if your checkout page does not support
              // relaunching in a new tab when returning from the Venmo app. This can
              // be omitted otherwise.
              allowNewBrowserTab: false
            }
    		if(RAC.cordovaWrapper){
    			venmoCreateObj.deepLinkReturnUrl = 'com.rac.racmobile.payments://';
    		}
      

      braintree.venmo.create(venmoCreateObj, function (venmoErr, venmoInstance) {
          if (venmoErr) {
            console.error('Error creating Venmo:', venmoErr);
            return;
          }
 
          // Verify browser support before proceeding.
          if (!venmoInstance.isBrowserSupported()) {
            console.log('Browser does not support Venmo');
            $('#Venmo-IPO').parents('.panel').hide();
            return;
          }
 
          displayVenmoButton(venmoInstance);
 
          // Check if tokenization results already exist. This occurs when your
          // checkout page is relaunched in a new tab. This step can be omitted
          // if allowNewBrowserTab is false.
          if (venmoInstance.hasTokenizationResult()) {
            venmoInstance.tokenize(function (tokenizeErr, payload) {
              if (err) {
                handleVenmoError(tokenizeErr);
              } else {
                handleVenmoSuccess(payload);
              }
            });
            return;
          }
        });
    });
      
 
   
 
    function displayVenmoButton(venmoInstance) {


	
		if (RAC.cordovaWrapper) {

			venmoButtonWrapper();
			venmoButton.addEventListener('click', function() {
				venmoButton.disabled = true;
                RAC.venmoButtonClicked = true;
				venmoInstance.tokenize(function(tokenizeErr, payload) {
					venmoButton.removeAttribute('disabled');

					if (tokenizeErr) {
						handleVenmoError(tokenizeErr);
					} else {
						handleVenmoSuccess(payload);
					}
				});
			});

		} else {

			// Assumes that venmoButton is initially display: none.
			venmoButton.style.display = 'block';

			venmoButton.addEventListener('click', function() {
				venmoButton.disabled = true;

				venmoInstance.tokenize(function(tokenizeErr, payload) {
					venmoButton.removeAttribute('disabled');

					if (tokenizeErr) {
						handleVenmoError(tokenizeErr);
					} else {
						handleVenmoSuccess(payload);
					}
				});
			});

		}
    }
}

function enrollClickNow() {
    var buttonElem = $(this);
    buttonElem.attr("disabled", true);
    sessionTimeoutCall().done(
        function () {
            handleSpinner();
            RAC.loadingSpinnerShow();
            $.ajax({
                url: "/myaccount/getPaymentMethods",
                type: "post",
                contentType: 'application/json',
                success: function (data) {
                    if (data != null && data != 'undefined' && data == 'true') {
                        RAC.loadingSpinnerHide();
                        $('#agreementIdsForm').submit();
                    } else {
                        RAC.loadingSpinnerHide();
                        $('#agreementIdsForm').submit();
                    }
                }
            });
        }).fail(function () {
        $('#serviceErrorPopup').modal('show');
    }).always(function () {
        buttonElem.attr("disabled", false);
    });
}

$('.processing-modal').on('shown.bs.modal', function() {
	$('.modal-backdrop').css('background-color', 'black');
});

$('#deletePopup').on('shown.bs.modal', function() {
	$('.modal-backdrop').css('background-color', 'black');
});



function setTheDeafultBankModal() { 
	$('#bank-form')[0].reset();
	$('#bank-form').parsley('reset');
	$( ".bankValid span ul li" ).empty();
	$( ".bankValid span ul" ).css('list-style','none');
	$(".bankValid").removeClass('has-error');
	$(".bankValid").removeClass('has-success');
	$("#bankAccApartment").parent().removeClass('has-error');
	$("#bankAccApartment").parent().removeClass('has-success');
	$('.bankValid .filter-option').text("");
	$(".successchk").removeClass('check-circle');
	$('.bankValid #bankAccState option:eq(0)').attr('selected','selected'); 
	$('#addBankAccountSave').addClass('btn-disabled');
  }

function setTheDeafultCreditCardModal() { 
	$(".creditValid input").val("");
	$('#credit-form').parsley('reset');
	$( ".creditValid span ul li" ).empty();
	$( ".creditValid span ul" ).css('list-style','none');
	$(".creditValid").removeClass('has-error');
	$(".creditValid").removeClass('has-success');
	$(".creditValid input").removeAttr('disabled');
	$("#nameAddrApartment").removeAttr('disabled');
	$("#checkBillingAddress" ).prop( "checked", false );
	$("#ccTermsAndCond" ).prop( "checked", false );
	$("#nameAddrselectState").removeAttr('disabled');
	$("#nameAddrselectState").selectpicker('refresh');
	$("#nameAddrApartment").val("");
	$(".successchk").removeClass('check-circle');
	$('.creditValid .filter-option').text("");
	$('.creditValid #nameAddrselectState option:eq(0)').attr('selected','selected'); 
	$('#nameAddrInfoPopupSave').attr('disabled', 'disabled');
  }

  function updatePaymentMethodHeader() {
  	$( ".pmMethod").removeClass( "error-border" );
	$( ".pmMethod-label").removeClass( "font-red" );
	$(".pmMethoderror").hide();
  	$('.pmMethod-label').removeClass("col-xs-4").addClass("col-xs-8");
    $('.pm-change-wrapper').removeClass("col-xs-8").addClass("col-xs-4");
      // Replace 'Add' with 'Change' when there payment method available
    $('.change-pm-label').removeClass("hide");
    $('.add-pm-label').addClass("hide");
    $('.pm-change-wrapper .link-btn').removeClass("add-link-btn");
    $('.pm-change-wrapper .link-btn').addClass("change-link-btn");
    $("#changePaymentDetails .choose-payment-method").removeClass('hide');
    
  }

  function venmoButtonClick(flow){
	  if(flow === "IPO"){
	  $.ajax({
          url: "/wrapperVenmoResponse",
          type: "get",
          contentType: 'application/json',
          success: function(data){
        	  if (!data) {
        		  venmoButtonClick("IPO");
        	  } else {
        		  var venmoPayload = JSON.parse(data);
        		  sessionStorage.removeItem('payload');
        		  handleVenmoSuccess(venmoPayload); 
        	  }
          }
      });
	  } else if(flow === "OTP"){

		  $.ajax({
	          url: "/wrapperVenmoResponse",
	          type: "get",
	          contentType: 'application/json',
	          success: function(data){
	        	  if (!data) {
	        		  venmoButtonClick("OTP");
	        	  } else {
	        		  var venmoPayloadOTP = JSON.parse(data);
	        		  sessionStorage.removeItem('payload');
	        		  handleVenmoSuccess(venmoPayloadOTP); 
	        	  }
	          }
	      });
		  
	  } else if(flow === "savePayment"){

		  $.ajax({
	          url: "/wrapperVenmoResponse",
	          type: "get",
	          contentType: 'application/json',
	          success: function(data){
	        	  if (!data) {
	        		  venmoButtonClick("savePayment");
	        	  } else {
	        		  var venmoPayloadSavePayment = JSON.parse(data);
	        		  sessionStorage.removeItem('payload');
	        		  handleVenmoSuccess(venmoPayloadSavePayment); 
	        	  }
	          }
	      });
		  
	  }
  }
  
  
  function handleVenmoError(err) {
    RAC.venmoButtonClicked = false;  
    RAC.loadingSpinnerHide();
    if (err.code === 'VENMO_CANCELED') {
  		console.log('App is not available or user aborted payment flow');
	} else if (err.code === 'VENMO_APP_CANCELED') {
	  console.log('User canceled payment flow');
	} else {
	  console.error('An error occurred:', err.message);
	}
  }

  function handleVenmoSuccess(payload) {
    if (!payload){ return; } // exit if payload isn't there
    RAC.venmoButtonClicked = false; // disable flag hiding spinner when switching back from Venmo app
  	// Send payload.nonce to your server.
	payload.deviceData = deviceData;
	// Display the Venmo username in your checkout UI.
	var customerName = payload.details.username;
    paypalPayload = payload;
    pageName = $('#page-name').val();
    if(pageName == "racReviewPaymentPage") {
        RAC.loadingSpinnerHide();
        // remove existing venmo radio button
        $(".changePayment .braintree-venmo-wrapper").remove();
        
        // remove radio button selection
        $(".cardDetail input[name='radio']").prop('checked', false);
        $(".cardDetail").removeClass('cardDet');

        // Add Venmo radio button 
        var pmType = "Venmo";
		var paymentMethodEle = '<div class="braintree-venmo-wrapper payment-method paypalUserInfo bdr3 border-green border-width-1 dpflex align-center marginT23"><label class="checktest radioColor cardDetail cardDet" id="payMethod-' + paymentMethodCounter + '"><input type="radio" name="selectedpayment" id="payMethodRadio-' + paymentMethodCounter + '" class="braintree-payments"><span class="label-text font13 fontMob12 cardValue display-block ellipsis"><span class="cardValue-number text-lowercase-imp">' + customerName + '</span></span><input type="hidden" class="methodObj" value="'+ escape(JSON.stringify(payload)) +'"></label></div>';
		updatePaymentMethodHeader();
        $(".venmo-det-box").prepend(paymentMethodEle);
		var venmoRadioId = "#payMethodRadio-" + paymentMethodCounter;
		$(venmoRadioId).prop('checked', true).trigger('change');
		$('#paymentMethodErrorSecureVenmo').addClass('hidden');
		if($('#Venmo-IPO .tnc').is(':checked')) {
			$('#Venmo-IPO').find('#pay-now-btn1').removeClass('btn-disabled');
		}
    } else if(pageName == "racPaymentMethodsPage") {
        // Don't hide the spinner in this case, as we're about to reload the page in the addPayPalOrVenmoPaymentMethod call
        addPayPalOrVenmoPaymentMethod(payload);
    } else {
        RAC.loadingSpinnerHide();
    }
    $(".payload").attr("paymentmethodid",JSON.stringify(payload));
    $(".venmoUserInfo").css("display","flex");
    $(".venmoUserInfo span:last-child").html(customerName);
    $("#paymentMethodErrorSecureVenmo").addClass("hidden");
    $('#Venmo-IPO .payment-section').find('#secureFormSubmit').removeClass('no-pm-selected');
    if($('#Venmo-IPO .payment-section .securetermsandcond').is(':checked')) {
        $('#Venmo-IPO .payment-section').find('#secureFormSubmit').removeClass('btn-disabled');
    }
  }

  function addPayPalOrVenmoPaymentMethod(payload) {
	handleSpinner();
	RAC.loadingSpinnerShow();
	$.ajax({
		url : '/addPayPalPaymentMethod',
		data : JSON.stringify(payload),
		type : 'POST',
		contentType : 'application/json',
		success : function(data) {
			if(pageName == "racPaymentMethodsPage") {
				if ((JSON.parse(data).paymentMethod) != null) {
					window.location.href = ACC.config.encodedContextPath + "/myaccount/paymentmethods";
					window.switchBrowserRefreshWarning(false);
				} else {
					RAC.loadingSpinnerHide();
					$('#technicalDifficultiesPopup').modal('show');
				}
			}
		},
		error : function(error) {
			RAC.loadingSpinnerHide();
			$('#technicalDifficultiesPopup').modal('show');
		},
		complete : function() {
			RAC.loadingSpinnerHide();
		}
	});
}
  
  function venmoButtonWrapper(){

	    var venmoButton = document.getElementById('venmo-button');
		var venmoEnabled = getCookieByName("venmoEnabled");
		
	if(venmoButton){
		var venmoIPOEle = $("#venmo-ipo-tab");
		if (venmoEnabled != "" && venmoEnabled === "true") {
			venmoButton.style.display = 'block';

			if (venmoIPOEle) {
				$(venmoIPOEle).removeClass("bg-grey");
				$(venmoIPOEle).attr("disabled", false);
			}
		} else {
			venmoButton.style.display = 'none';

			if (venmoIPOEle) {
				$(venmoIPOEle).addClass("bg-grey");
				$(venmoIPOEle).attr("disabled", true);
			}
		}

		var checkVenmoCookie = function() {
			var lastCookie = getCookieByName("venmoEnabled");

			return function() {
				var currentCookie = getCookieByName("venmoEnabled");

				if (currentCookie != lastCookie) {
					if (currentCookie != "" && currentCookie === "true") {
						venmoButton.style.display = 'block';

						if (venmoIPOEle) {
							$(venmoIPOEle).removeClass("bg-grey");
							$(venmoIPOEle).attr("disabled", false);
						}
					} else {
						venmoButton.style.display = 'none';

						if (venmoIPOEle) {
							$(venmoIPOEle).addClass("bg-grey");
							$(venmoIPOEle).attr("disabled", true);
						}
					}
					lastCookie = currentCookie; // store latest cookie
				}
			};
		}();
		window.setInterval(checkVenmoCookie, 100);
  	}
  }

	function personalDetailsInfo() {
	handleSpinner();
	RAC.loadingSpinnerShow();
	sessionTimeoutCall().done(function(data, textStatus) {
		if (!checkLoginSessionTimeout(data)) {
			$.ajax({
                url : ACC.config.encodedContextPath + "/myaccount/profile",
				type : "get",
				success : function(data) {
					if (data) {
						$('.result').html(data);
						if($('#showPersonalInfoModel').val() === 'false'){
						    var logoutURL = $("#logoutURL").val();
						    signOut(logoutURL);
						}else{
						    RAC.loadingSpinnerHide();
						    $('#personalInfoModal').modal('show');
						}
					} else {
						RAC.loadingSpinnerHide();
						$('#serviceErrorPopup').modal('show');
					}
				},
				error : function(error) {
					RAC.loadingSpinnerHide();
					$('#serviceErrorPopup').modal('show');
					console.log(error);
				},
				complete : function() {
					$('body').css("padding-right", "0px");
				}
			});
		} else {
			window.location.href = "/";
		}
	}).fail(function(jqXHR, textStatus, errorThrown) {
		$('#serviceErrorPopup').modal('show');
	}).always(function() {
	});
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		'event':'QL-personal-info-click',
	});
};

$('body').on('change', '.saveOtpPayment:visible input', function() {
   if($(this).is(':checked')) {
      $('#creditCardForm #savePaymentMethod').val(true);
      savePaymentMethod = true;
   } else {
      $('#creditCardForm #savePaymentMethod').val(false);
      savePaymentMethod = false;
   }
});

$('#savedPaymentMethod .saved-card-exp span').each(function() {
    var cuurentFormat = $(this).html();
    var modifiedFormat = cuurentFormat.slice(0, 1) + cuurentFormat.charAt(1) + '/' + cuurentFormat.slice(2);
    $(this).html(modifiedFormat);
});
// Disable Venmo on Desktop  Saved Payment
$(document).ready(function() {
    if ($(window).width() > 768) {
        $("#venmo-ipo-tab").prop("disabled", "true");
        $("#venmo-ipo-tab").css("background-color", "#f0f0f0");
        $("#venmo-ipo-tab").css("color", "#cacaca");
        $("#venmo-ipo-tab").css("cursor", "not-allowed");
    }
    $("#checkBillingAddress").prop('checked', true).trigger('change');
    $("#checkBankBillingAddress").prop('checked', true).trigger('change');
});

// Event listener for clicking on panel buttons
$(document).on('click', '.agreement-new-payment.payment-panel-group .panel-heading button', function() {
    $(".agreement-new-payment.payment-panel-group .panel").removeClass('rac-border-green');
    $(this).closest('.panel').addClass('rac-border-green');
});

// Event listener for panel collapse
$(document).on('hide.bs.collapse', '.agreement-new-payment.payment-panel-group .panel-collapse', function () {
    $(this).closest('.panel').removeClass('rac-border-green');
});

// Event listener for panel shown
$(document).on('shown.bs.collapse', '.agreement-new-payment.payment-panel-group .panel-collapse', function () {
    let currentTab = $(this).closest('.panel');
    let currentTabTop = $(currentTab).offset().top;
	let viewportTop = $(window).scrollTop();
	if(currentTabTop < viewportTop) {
		$('html, body').animate({
			scrollTop: $(currentTab).offset().top - 15
		}, 300);
	}
});

// Tracking Shop Deals
$('.deals-promotion-card a > img').on('click', function() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'myaccount-shopdeals-click'
    });
});

var cashAppType, cashAppAuthResponse;
function addCashAppPaymentMethod() {
	if (cashAppAuthResponse != null) {
		return;
	}
	// Passing 1 dollar for Authorize cashapp and it will be reverted from services team
	if ($('#page-name').val() == "racPaymentMethodsPage") {
		var cashAppData = {
			'amount': "1",
			'emailAddress': $('#userEmail').val(),
			'globalCustomerId': $('#globalCustomerId').val(),
			'storeNumber': storeNumber,
			'firstName': $('#userFirstName').val().trim(),
			'lastName': $('#userLastName').val().trim(),
		};
	} else {
		var cashAppData = {
			'amount': $('.total-payment-amount').text().replace(/[^0-9\.]/g, ''),
			'emailAddress': $('#userEmail').val(),
			'globalCustomerId': $('#globalCustomerId').val(),
			'storeNumber': storeNumber,
			'firstName': $('#userFirstName').val().trim(),
			'lastName': $('#userLastName').val().trim(),
		};
	}
	$.ajax({
		url: '/initializeCashApp',
		data: JSON.stringify(cashAppData),
		type: 'POST',
		contentType: 'application/json',
		success: function(response) {
			console.log(response);
			if (response.statusCode == "A") {
				cashAppAuthResponse = response;
				cashAppType = response.tokenInfo.cardType;
				createCustomButton();
				renderCashAppPayButton(response)
				//initializeCashApp(response);
			}
		},
		error: function(error) { }
	});
}

function initializeCashApp(cashAppAuthorizeResponse) {
	var cashAppPayOptions = {
		requestOnFileGrant: true,
		button: {
			size: 'medium', // "medium" | "small"
			width: 'full', // "full" | "static"
			theme: 'dark', // "dark" | "light"
			shape: 'semiround' // "round" | "semiround"
		},
		redirectUrl: window.location.href,
		onComplete: function(event) {
			console.log(JSON.stringify(event));
			if (event.data.cashtag !== undefined && event.data.cashtag !== null && event.data.cashtag !== "") {
				cashAppResponse = event.data;
				paymentData = cashAppResponse;

				if ($('#page-name').val() == "racPaymentMethodsPage" && cashAppAuthResponse !== null && cashAppResponse !== null) {
					var tokenInfo = {
						"paymentToken": cashAppAuthResponse.tokenInfo.paymentToken,
						"cardType": cashAppAuthResponse.tokenInfo.cardType,
						"grantId": cashAppResponse.grantId,
						"expirationDate": cashAppResponse.expiresAt,
						"cashtag": cashAppResponse.cashtag
					}
					var racCashAppPaymentData = {
						"externalOrderId": cashAppAuthResponse.extTransactionId,
						"tokenInfo": tokenInfo,
						"amount": "1.00",
						"globalCustomerId": $('#globalCustomerId').val()
					}
					var paymentMethodData = {
						"paymentRegistrationId": paymentData.grantId,
						"authTransactionId": paymentData.id,
						"cardExpiration": paymentData.expiresAt
					};
					var makePaymentRequest = {
						"paymentMethodData": paymentMethodData,
						"racCashAppPaymentData": racCashAppPaymentData
					}
					handleSpinner();
					RAC.loadingSpinnerShow();
					$.ajax({
						url: ACC.config.encodedContextPath + '/addCashAppPaymentMethod',
						data: JSON.stringify(makePaymentRequest),
						type: 'POST',
						contentType: 'application/json',
						success: function(data) {
							if (pageName == "racPaymentMethodsPage") {
								if ((JSON.parse(data).paymentMethod) != null) {
									window.location.href = ACC.config.encodedContextPath + "/myaccount/paymentmethods";
									window.switchBrowserRefreshWarning(false);
									RAC.loadingSpinnerHide();
								} else {
									RAC.loadingSpinnerHide();
									$('#technicalDifficultiesPopup').modal('show');
								}
							}
						},
						error: function(error) {
							RAC.loadingSpinnerHide();
							$('#technicalDifficultiesPopup').modal('show');
						},
						complete: function() {
							RAC.loadingSpinnerHide();
						}
					});

				} else {
					$(".payload").attr("paymentmethodid", JSON.stringify(cashAppResponse));
					paymentMethodCounter++;
					var pmType = cashAppResponse.cashtag;
					var paymentMethodEle = `<div class="cashApp-wrapper payment-method marginB10 border-green border-width-1 bdr3"><label class="checktest radioColor cardDetail cardDet pos-relative cursor-pointer" for="payMethodRadio1-${paymentMethodCounter}"><input type="radio" name="selectedpayment" id="payMethodRadio1-${paymentMethodCounter}" class="cashApp-payments" checked><span class="label-text font13 fontMob12 cardValue display-block ellipsis"><span class="paddingR10 cardValue-type vertical-middle">${pmType}</span></span><input type="hidden" class="methodObj" value="${escape(JSON.stringify(cashAppResponse))}"></label></div>`;
					console.log(JSON.stringify(cashAppResponse));
					$("#cashApp-IPO .payment-option-wrapper").html(paymentMethodEle);
					var cashAppRadioId = "#payMethodRadio1-" + paymentMethodCounter;
					$(cashAppRadioId).prop('checked', true).trigger('change');
					if ($("#cashApp-IPO").find(".securetermsandcond").is(":checked")) {
						$("#cashApp-IPO").find('.payment-section').find('#secureFormSubmit').attr('disabled', false);
					}
					if ($("#cashAppPaySection").find(".tnc").is(":checked")) {
						$("#cashAppPaySection").find('#pay-now-btn1').removeClass('btn-disabled');
						if ($('#page-name').val() == "racReviewAutopayPage") {
							$("#cashAppPaySection").find('#submitNewCashapp').removeClass('btn-disabled');
						}
					}
					getMyAccountPaymentOptions();
				}
			}
			RAC.loadingSpinnerHide();
		},
		eventListeners: {
			"CUSTOMER_INTERACTION": ({
				isMobile
			}) => {
				console.log(`CUSTOMER_INTERACTION`)
				if (isMobile) {
				} else {
					// captureDesktopMetrics()
				};
			},
			"CUSTOMER_REQUEST_DECLINED": () => {
				$('#paymentMethodErrorSecureCashApp').addClass('hidden');
				$('#cashAppErrorMessage1').removeClass('hidden');
				RAC.loadingSpinnerHide();
				console.log(`CUSTOMER_REQUEST_DECLINED`)
			},
			"CUSTOMER_REQUEST_APPROVED": () => {
				$('#cashAppErrorMessage1').addClass('hidden');
				$('#paymentMethodErrorSecureCashApp').addClass('hidden');
				RAC.loadingSpinnerHide();
				$('.cash-app-pay-custom-button').attr('disabled', 'disabled');
				console.log(`CUSTOMER_REQUEST_APPROVED`)
			},
			"CUSTOMER_REQUEST_FAILED": () => {
				$('#cashAppErrorMessage1').removeClass('hidden');
				$('#paymentMethodErrorSecureCashApp').addClass('hidden');
				RAC.loadingSpinnerHide();
				console.log(`CUSTOMER_REQUEST_FAILED`)
			}
		}
	}
	AfterPay.initializeForCashAppPay({
		countryCode: "US",
		token: cashAppAuthorizeResponse.tokenInfo.paymentToken,
		cashAppPayOptions
	});
}

function createCustomButton() {
	// Create custom button
	const cashAppPayCustomButton = document.createElement('button');
	cashAppPayCustomButton.classList.add('cash-app-pay-custom-button', 'w-100', 'center-content', 'bdr4', 'paddingT10', 'paddingB5', 'hide');

	// Attach cash app pay logo to button
	const cashAppPayLogo = document.createElement('cash-app-pay-logo');
	cashAppPayCustomButton.appendChild(cashAppPayLogo);
	document.getElementById('cash-app-pay').after(cashAppPayCustomButton);
	setTimeout(() => {
		$('#cash-app-pay').removeClass('loading-anim');
		$('.cash-app-pay-custom-button').removeClass('hide');
	}, 1000);
}

// Load PayKit and render the CashAppPayButton without making any additional API calls. Token from createCheckout is not required.
function renderCashAppPayButton(cashAppAuthorizeResponse) {
	var cashAppPayButtonOptions = {
		button: false,
		manage: false,
		onBegin: function({ begin }) {
			addEventListenerToCashAppPayButton(async (event) => {
				event.preventDefault();
				//const token = await createCheckout(true);
				initializeCashApp(cashAppAuthorizeResponse);
				begin();
			});
			const shadowRoot = document.querySelector('cash-app-pay-logo').shadowRoot;
			const svgElement = shadowRoot.querySelector('svg path:last-of-type');
			svgElement.setAttribute('fill', '#fff');
		},
	}
	//This allows you to render the button without a token
	AfterPay.renderCashAppPayButton({
		countryCode: "US",
		cashAppPayButtonOptions
	});
}

function addEventListenerToCashAppPayButton(func) {
	const customButton = document.querySelector('.cash-app-pay-custom-button')
	customButton.addEventListener('click', func);
}

$("#submitNewCashapp").on('click', function(){
	let currentParentPanel = $(this).parents('.panel');
	if (currentParentPanel.find('label.cardDetail').length && currentParentPanel.find('label.cardDetail input[name=selectedpayment]').is(':checked')) {
		$(this).closest('.panel-body').find('#paymentMethodErrorSecureCashApp').addClass('hidden');
	} else {
		$(this).closest('.panel-body').find('#paymentMethodErrorSecureCashApp').removeClass('hidden');
		return false;
	}
	let currentCardTerms = $(this).closest('.panel').find('.tnc.cashapp');
	if(isFalse(toggleAutoPayTnCSubmit(currentCardTerms))) {
		return false;
	}
	let pageName = $('#page-name').val();
	if (!isNullUndefined(cashAppAuthResponse)) {
		$("#cExternalOrderId").val(cashAppAuthResponse.extTransactionId);
		if (cashAppAuthResponse.tokenInfo !== null) {
			$("#cPaymentToken").val(cashAppAuthResponse.tokenInfo.paymentToken);
			$("#cCardType").val(cashAppAuthResponse.tokenInfo.cardType);
		}
	}	
	if (!isNullUndefined(cashAppResponse)) {
		$("#cExpirationDate").val(cashAppResponse.expiresAt);
		$("#cGrantId").val(cashAppResponse.grantId);
		$("#cCashTag").val(cashAppResponse.cashtag);
		$("#cCashAppId").val(cashAppResponse.id);
	}
	$("#cAmount").val($('.cash-total-payment-amount').text().replace(/[^0-9\.]/g, ''));
    if (pageName == "racReviewAutopayPage") {
    	if ($(this).parents('.panel-body').find('.cashApp-payments:checked').length < 1) {
    		$(this).parents('.panel-body').find('#paymentMethodErrorSecureCashApp').removeClass('hidden');
    	} else {
			var clonedFormInputs = $(".enrollAutopayForm").clone(true);
			$("#cashApp-form").append(clonedFormInputs);
			$("#cashAppForm").submit();
        }
    }
});