var errorMsgsFormfirst = {
	firstNameReq : ACC.firstNameReq,
    firstNamePattern : ACC.firstNamePatternErr,
    lastNameReq : ACC.lastNameReq,
    lastNamePattern : ACC.lastNamePatternErr,
    agreementNumber :   ACC.agreementNoInvalidErr
	};

$(document).ready(function() {
	if ($('#customerEmail').text()) {
		var prevUrl = document.referrer;
		if (prevUrl.toLocaleLowerCase().indexOf("oktapreview.com") != -1) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event' : 'account-login-form-success',
				'userId' : $('#customerEmail').text()
			});
		}
	}
    $(".linkButton").on('click',function(){
       
        validateAndSubmitPersonalInfo();
    });
    $('[data-toggle="tooltip"]').tooltip();

	function checkLoginSessionTimeout(response){
		var data = response?JSON.parse(response):null;
		return data && data.sessionTimeout? true: false;
	}

	// Generic function to make an AJAX call
	var sessionTimeoutCall = function() {
		// Return the $.ajax promise
		return $.ajax({
			type:"GET",
			url: '/myaccount/refresh'
		});
	};
	
    //$(".customerLeftErrorPaneTitle").hide();
    var $firstName = $("#firstName"), $lastName = $("#lastName"),$agreementNumber = $("#agreementNumber");
    if( $firstName.length ) {
		$firstName.parsley({
			trigger : null,
			errorsContainer : ".errorMsgFirstName",
			required : true,
			length : "[2,30]",
			pattern: "/^[A-Za-z' -.]*$/",
			requiredMessage : errorMsgsFormfirst.firstNameReq,
			patternMessage: errorMsgsFormfirst.firstNamePattern,
			lengthMessage : errorMsgsFormfirst.firstNameReq
		});
	}
    
	if( $lastName.length ) {
		$lastName.parsley({
			trigger : null,
			errorsContainer : ".errorMsgLastName",
			required : true,
			length : "[2,30]",
			pattern: "/^[A-Za-z' -.]*$/",
			requiredMessage : errorMsgsFormfirst.lastNameReq,
			patternMessage: errorMsgsFormfirst.lastNamePattern,
			lengthMessage : errorMsgsFormfirst.lastNameReq
		});		
	}

	if( $agreementNumber.length ) {
		$agreementNumber.parsley({
			trigger : null,
			errorsContainer : ".errorMsgAgreementNumber",
		   required : true,
		   length : "[2,19]",
		   pattern: "/^[A-Za-z0-9' -]*$/",
		   requiredMessage : errorMsgsFormfirst.agreementNumber,
		   patternMessage: errorMsgsFormfirst.agreementNumber,
		   lengthMessage : errorMsgsFormfirst.agreementNumber
	   });	
	}
    
    function validateAndSubmitPersonalInfo(){
        firstNameInstance = $firstName.parsley();
        lastNameInstance = $lastName.parsley();
        agreementNumberInstance =   $agreementNumber.parsley();
        
        firstNameInstance.validate();
        lastNameInstance.validate();
        agreementNumberInstance.validate();
       
         if (firstNameInstance.isValid() && lastNameInstance.isValid()  && agreementNumberInstance.isValid() ){
        	 var curURL=location.href;
        	 var URLsplit=curURL.split('=');
        	 var customerReqObj = {
                firstName: $firstName.val().trim(),
                lastName: $lastName.val().trim(),
               
                agreementNumber: $agreementNumber.val().trim(),
              
                guid: URLsplit[1]

            };
             $(".link-processing-modal").modal({backdrop: 'static', keyboard: false});
			 sessionTimeoutCall()
				 .done(function(data, textStatus) {
					 if(!checkLoginSessionTimeout(data)){
						 $.ajax({
							 url: "/myaccount/link-customer",
							 type: "post",
							 data: JSON.stringify(customerReqObj),
							 dataType: "json",
							 contentType: 'application/json; charset=utf-8',
							 success: function(data) {
								 if(data.statusCode=="200"){
									 if(data.flow=="error"){
									  //_satellite.track("my_acct_dataNotFound_error");
										 $('.customerLeftPaneTitle').css("display","none");
										 $('.customerLeftErrorPaneTitle').css("display","block");
										 $('.customerLeftErrorPaneTitle').removeClass("hidden");
										 $('.customerLeftErrorPaneTitle').addClass("err-icon");
									 }
									 else if(data.flow=="popup"){
									 // _satellite.track("my_acct_already_linked_error");
										 $('#prevAccountLinking').modal({
											 backdrop: 'static',
											 keyboard: false
										 });
										 $('#prevAccountLinking span').text(maskEmail(data.linkedemailID));
										 $('#prevAccountLinking').modal('show');
									 }
									 else if (data.flow=="success") {
										 window.dataLayer = window.dataLayer || [];
										 window.dataLayer.push({
											 'event': 'custom-event-account',
											 'accCategory': 'Account',
											 'accAction': 'agreement linked success'
										 });
										 window.location.href = '/myaccount/agreements';
									 }
									 else if(data.flow=="maxTry"){
										 window.location.href = '/myaccount/linkcustomer/error';
									 }
									 else if(data.flow==null){
										 $('.customerLeftPaneTitle').css("display","none");
										 $('.ESBcustomerLeftErrorPaneTitle').removeClass("hidden");
										 $('.ESBcustomerLeftErrorPaneTitle').addClass("err-icon");
									}
								 }
								 else{
									 if(data.flow=="maxTry"){
										 window.location.href = '/myaccount/linkcustomer/error';
									 }
									 else
									 {

										 window.location.href = '/error';
									 }
								 }
							 },
							 error: function(error) {
								 window.location.href = '/error';
							 },
							 complete: function() {
								 $('.link-processing-modal').modal('hide');							 }
						 });
					 }
					 else{
						 window.location.href = "/";
					 }
				 })
				 .fail(function(jqXHR, textStatus, errorThrown) {
					 //$('#serviceErrorPopup').modal('show');
				 })
				 .always(function(){
				 });
             
            
             
            /* $('#prevAccountLinking').modal({					
            backdrop: 'static',
            keyboard: false
        });
        $('#prevAccountLinking').modal('show');*/
             
             
         }
        else{
             $('html, body').animate({
                scrollTop: $(".customerForm").offset().top - 50
            }, 1000);
        }
    }
    
    $("#customerMatchPopup-SignOutBtn").on('click', function(){
		window.switchBrowserRefreshWarning(false);
    	var signOutURL = $(this).attr('data-url');
    	$.ajax({
    		crossDomain: true,
    		url: signOutURL,
    		type: "get",
            dataType: "jsonp",
            success: function(data) {
               console.log("in success logout: " ,data);
               
            },
            error: function(error) {
            	console.log("in error logout");
                console.log("in error obj: " ,error);
               
            }, complete: function(){
            	 parent.location.href='/racsso/saml/logout?local=true';
            	console.log("in complete logout");
            }
            //beforeSend: setHeader
        });
    });
    
    (function() {
      $('.linkValid input').on('input', function() {
          var empty = false;
          $('.linkValid input').each(function() {
              if ($(this).val().trim() === '') {
                  empty = true;
              }
          });
        if (empty) {
              $('.linkButton').attr('disabled', 'disabled');
          } else {
              $('.linkButton').removeAttr('disabled');
          }  
         
      });
      })();
});
