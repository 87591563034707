function checkResize(type) {
  "use strict";
  var numColumns;
  if (type === 'comparePhones') {
    if (window.innerWidth <= 480){
      numColumns = 2;
    }
    else if (window.innerWidth <= 767){
      numColumns = 3;
    }
    else if (window.innerWidth <= 1024){
      numColumns = 4;
    }
    else{
      numColumns = 6;
    }
    $('#phone-compare-carousel').tableCarousel('update', {
      columns: numColumns
    });
    $('#phone-compare-carousel').tableCarousel('resize');

    $('#phone-compare-carousel').tableCarousel('resize');
  }
  else if (type === 'comparePlans') {
    if (window.innerWidth <= 480){
      numColumns = 2;
    }
    else{
      numColumns = 3;
    }
    $('.country-list').listColumnizer({
      maxColumns: numColumns
    });
  }
}