$(document).ready(function() {
$('#compare-phone-container .smartphoneShopDeal').removeClass('visible-xs');
$('#compare-phone-container .smartphoneShopDeal').addClass('hidden');
$('.left-scroller').css('pointer-events', 'none');
$('.left-scroller .fa-caret-circle-left').css('color', '#808080');
var cachedWidth = $(window).width();
var numOfPhones = $( "#number-of-phones" ).val();
	if(numOfPhones > 5) {
		$('#phone-compare-carousel').tableCarousel({
			columns : 5
		});
		$(".left-scroller").show();
		$(".right-scroller").show();
	}
	else {
		$('#phone-compare-carousel').tableCarousel({
			columns : numOfPhones
		});
		$(".left-scroller").hide();
		$(".right-scroller").hide();
	}
	$(window).resize(function() {
	var newWidth = $(window).width();
	if(newWidth !== cachedWidth){
		checkResize();
		cachedWidth = newWidth;
	}
	});
	checkResize();
	$('#side-menu li:nth-child(2) a').addClass('active');
	$(".compare-plan-heading, .compareplan-banner").each(function(index) {
		if(!$.trim($(this).html()).length) {
			$(this).parent().hide();
		}
	});
	
	});
function checkResize() {
  "use strict";
  var numColumns;
  var numOfPhones = $( "#number-of-phones" ).val();
  if (window.innerWidth <= 480){
    numColumns = 2;
    $(".left-scroller").show();
	$(".right-scroller").show();
  }
  else if (window.innerWidth <= 767){
    numColumns = 3;
    $(".left-scroller").show();
	$(".right-scroller").show();
  }
  else if (window.innerWidth <= 1024){
    numColumns = 4;
    $(".left-scroller").show();
	$(".right-scroller").show();
  }
  else{
  	if(numOfPhones > 5) {
    	numColumns = 5;
    	$(".left-scroller").show();
		$(".right-scroller").show();
    }
    else {
    	numColumns = numOfPhones;
    	$(".left-scroller").hide();
		$(".right-scroller").hide();
    }
  }
  $('#phone-compare-carousel').tableCarousel('update', {
    columns: numColumns
  });
  $('#phone-compare-carousel').tableCarousel('resize');

}
//Product Card Swipe Start
$("#table-carousel-header-img").swipe( 
	      {
	        swipeLeft:function(event, direction, distance, duration, fingerCount) 
	        {
	            
	        	$('#phone-compare-carousel').tableCarousel('left');
	        },
	        swipeRight:function(event, direction, distance, duration, fingerCount) 
	        {
	           
	        	$('#phone-compare-carousel').tableCarousel('right');
	        },
	        threshold:4,
	        excludedElements:"button, input, select, textarea",
	        allowPageScroll:"vertical"
	        
	      });
//Product Card Swipe End