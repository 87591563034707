ACC.productTabs = {

	bindAll: function () {
		if ($('#productTabs').length > 0) {

			// only load review one at init 
			ACC.productTabs.showReviewsAction("reviews");

			ACC.productTabs.productTabs = $('#productTabs').accessibleTabs({
				wrapperClass: 'content',
				currentClass: 'current',
				tabhead: '.tabHead',
				tabbody: '.tabBody',
				fx: 'show',
				fxspeed: null,
				currentInfoText: 'current tab: ',
				currentInfoPosition: 'prepend',
				currentInfoClass: 'current-info',
				autoAnchor: true
			});


			$(document).on("click", '#write_review_action_main, #write_review_action', function (e) {
				e.preventDefault();
				ACC.productTabs.scrollToReviewTab('#write_reviews')
				$('#reviewForm input[name=headline]').focus();
			});

			$('#based_on_reviews, #read_reviews_action').bind("click", function (e) {
				e.preventDefault();
				ACC.productTabs.scrollToReviewTab('#reviews')
			});


			$(document).on("click", '#show_all_reviews_top_action, #show_all_reviews_bottom_action', function (e) {
				e.preventDefault();
				ACC.productTabs.showReviewsAction("allreviews");
				$(this).hide();
			});

		}

	},

	scrollToReviewTab: function (pane) {
		$.scrollTo('#productTabs', 300, { axis: 'y' });
		ACC.productTabs.productTabs.showAccessibleTabSelector('#tab-reviews');
		$('#write_reviews,#reviews').hide();
		$(pane).show();
	},

	showReviewsAction: function (s) {
		$.get($("#reviews").data(s), function (result) {
			$('#reviews').html(result);
		});
	}
};

$(document).ready(function () {
	ACC.productTabs.bindAll();
	$("#owl-brand-carousel").owlCarousel({
		autoPlay: false,
		rewindNav: false,
		items: 3,
		itemsresponsive: [1199, 3],
		itemsresponsiveSmall: [979, 3],
		itemsTablet: [768, 2],
		itemsTabletSmall: [479, 1],
		itemsMobile: [479, 1],
		navigation: true,
		navigationText: ["", ""]
	});


	function setViewStyle(isList) {
		if (isList == "active") {
			$('.products .item').removeClass(
				'grid-group-item');
			$('.products .item').addClass(
				'list-group-item');
			$('#listview').addClass('active');
			$('#gridview').removeClass('active');


		} else {
			$('.products .item').removeClass(
				'list-group-item');
			$('.products .item').addClass(
				'grid-group-item');
			$('#gridview').addClass('active');
			$('#listview').removeClass('active');
		}
	}

	$('#listview').click(function (event) {
		$('.pv').val('listview');
		event.preventDefault();
		setViewStyle("active");
	});
	$('#gridview').click(function (event) {
		event.preventDefault();
		$('.pv').val('gridview');
		setViewStyle("inactive");
	});

	/*$('#page1').click(function() {
		$('.Page1').css("display", "block");
		$('.Page2').css("display", "none");
	});
	$('#page2').click(function() {
		$('.Page2').css("display", "block");
		$('.Page1').css("display", "none");
	});*/



	let defaultPaymentOption = $('.sort-form').find("input[name='paymentOption']").val();

	$('.pagination a, .plpPagination a.showAll').on('click', function (event) {
		var $linkEle = $(this);
		if ($linkEle.hasClass('clicked')) {
			$linkEle.removeClass('clicked');
			// Double click
		} else {
			$linkEle.addClass('clicked');
			updateURLForPaginations($linkEle);
			setTimeout(function () {
				if ($linkEle.hasClass('clicked')) {
					$linkEle.removeClass('clicked');
					// Single click  
				}
			}, 500);
		}
		return true;
	});

	$('a.productMainLink').on('click', function (event) {
		if ($(event.target).parents('.mix-bundle-tag').length > 0
			|| $('.mix-bundle-tag').find('.popover').length > 0
			|| $(event.target).hasClass('findStoreBtn')
			|| $(event.target).hasClass('detailsBtnarrow')) {
			event.preventDefault(); // Preventing page redirection to pdp in case of showing M&B tooltip
		}
	});

	$("input[name='price']").click(function () {
		var userPaymentOption = $("input[name='price']:checked").val();
		$('.paymentOption').val(userPaymentOption);
		//window.history.replaceState(document.URL,'',"&paymentOption="+userPaymentOption);
		activateUserPricingOption(userPaymentOption);
	});


	if (defaultPaymentOption != null && defaultPaymentOption == 'weekly') {
		$("#weekly-tab-button").parent().addClass("active");
		$("#semi-monthly-tab-button").parent().removeClass("active");
		$("#monthly-tab-button").parent().removeClass("active");
		$("#weekly-tab-button").attr("aria-expanded", "true");
		$("#semi-monthly-tab-button").attr("aria-expanded", "false");
		$("#monthly-tab-button").attr("aria-expanded", "false");
		$("#biweekly-tab-button").attr("aria-expanded", "false");
		$("#biweekly-tab-button").parent().removeClass("active");
		$('.price-summary-weekly').parent().show();
		$('.price-summary-smonthly').parent().hide();
		$('.price-summary-monthly').parent().hide();
		$('.price-summary-biweekly').parent().hide();
	}
	else if (defaultPaymentOption != null && defaultPaymentOption == 'semiMonthly') {
		$("#weekly-tab-button").parent().removeClass("active");
		$("#semi-monthly-tab-button").parent().addClass("active");
		$("#monthly-tab-button").parent().removeClass("active");
		$("#weekly-tab-button").attr("aria-expanded", "false");
		$("#biweekly-tab-button").attr("aria-expanded", "false");
		$("#biweekly-tab-button").parent().removeClass("active");
		$("#semi-monthly-tab-button").attr("aria-expanded", "true");
		$("#monthly-tab-button").attr("aria-expanded", "false");

		$('.price-summary-weekly').parent().hide();
		$('.price-summary-smonthly').parent().show();
		$('.price-summary-monthly').parent().hide();
		$('.price-summary-biweekly').parent().hide();
	}
	else if (defaultPaymentOption != null && defaultPaymentOption == 'biweekly') {
		$("#weekly-tab-button").parent().removeClass("active");
		$("#semi-monthly-tab-button").parent().removeClass("active");
		$("#monthly-tab-button").parent().removeClass("active");
		$("#biweekly-tab-button").parent().addClass("active");
		$("#weekly-tab-button").attr("aria-expanded", "false");
		$("#semi-monthly-tab-button").attr("aria-expanded", "false");
		$("#monthly-tab-button").attr("aria-expanded", "flase");
		$("#biweekly-tab-button").attr("aria-expanded", "true");

		$('.price-summary-weekly').parent().hide();
		$('.price-summary-smonthly').parent().hide();
		$('.price-summary-monthly').parent().hide();
		$('.price-summary-biweekly').parent().show();
	}
	else if (defaultPaymentOption != null && defaultPaymentOption == 'monthly') {
		$("#weekly-tab-button").parent().removeClass("active");
		$("#semi-monthly-tab-button").parent().removeClass("active");
		$("#monthly-tab-button").parent().addClass("active");
		$("#weekly-tab-button").attr("aria-expanded", "false");
		$("#semi-monthly-tab-button").attr("aria-expanded", "false");
		$("#monthly-tab-button").attr("aria-expanded", "true");
		$("#biweekly-tab-button").attr("aria-expanded", "false");
		$("#biweekly-tab-button").parent().removeClass("active");

		$('.price-summary-weekly').parent().hide();
		$('.price-summary-smonthly').parent().hide();
		$('.price-summary-monthly').parent().show();
		$('.price-summary-biweekly').parent().hide();
	}
	else {
		$("#weekly-tab-button").parent().addClass("active");
		$("#semi-monthly-tab-button").parent().removeClass("active");
		$("#monthly-tab-button").parent().removeClass("active");
		$("#weekly-tab-button").attr("aria-expanded", "true");
		$("#semi-monthly-tab-button").attr("aria-expanded", "false");
		$("#monthly-tab-button").attr("aria-expanded", "false");
		$("#biweekly-tab-button").attr("aria-expanded", "false");
		$("#biweekly-tab-button").parent().removeClass("active");

		$('.price-summary-weekly').parent().show();
		$('.price-summary-smonthly').parent().hide();
		$('.price-summary-monthly').parent().hide();
		$('.price-summary-biweekly').parent().hide();
	}

	/* Below js is for mobile view filter modal/dialog */
	$('#sort-filterModal #weekly-tab-button').on('click', function () {
		activateWeeklyOption();
	});
	$('#sort-filterModal #biweekly-tab-button').on('click', function () {
		activateBiWeeklyOption();
	});
	$('#sort-filterModal #semi-monthly-tab-button').on('click', function () {
		activateSemiMonthlyOption();
	});
	$('#sort-filterModal #monthly-tab-button').on('click', function () {
		activateMonthlyOption();
	});

});

function activateUserPricingOption(userPaymentOption) {
	if (userPaymentOption === 'weekly') {
		activateWeeklyOption();
	} else if (userPaymentOption === 'biweekly') {
		activateBiWeeklyOption();
	} else if (userPaymentOption === 'semiMonthly') {
		activateSemiMonthlyOption();
	} else {
		activateMonthlyOption();
	}
}

function activateWeeklyOption() {
	$('.price-summary-weekly').parent().show();
	$('.price-summary-biweekly').parent().hide();
	$('.price-summary-smonthly').parent().hide();
	$('.price-summary-monthly').parent().hide();
}

function activateBiWeeklyOption() {
	$('.price-summary-weekly').parent().hide();
	$('.price-summary-biweekly').parent().show();
	$('.price-summary-smonthly').parent().hide();
	$('.price-summary-monthly').parent().hide();
}

function activateSemiMonthlyOption() {
	$('.price-summary-weekly').parent().hide();
	$('.price-summary-biweekly').parent().hide();
	$('.price-summary-smonthly').parent().show();
	$('.price-summary-monthly').parent().hide();
}

function activateMonthlyOption() {
	$('.price-summary-weekly').parent().hide();
	$('.price-summary-biweekly').parent().hide();
	$('.price-summary-smonthly').parent().hide();
	$('.price-summary-monthly').parent().show();
}

function updateURLwithUserPaymentOption(priceOption) {
	let href;
	$('a.productMainLink').each(function(index, item) {
		href = $(item).attr('href');
		let queryParamString = href.substring(href.indexOf('?')+1);
		let queryParamArray = queryParamString.split('&');
		$.each(queryParamArray ,function(index, queryParam){
			if (queryParam.contains('paymentOption')) {
				href = href.replace(queryParam, "paymentOption="+priceOption);
				$(item).attr('href', href);
			}
		});
	});
}

function updateURLForPaginations(obj) {
	var url = new URL(window.location.protocol + "//" + window.location.host + $(obj).attr('href'));
	var search_params = url.searchParams;
	var userSelectedPaymentOption = $('.sort-form').find("input[name='paymentOption']").val();
	var userSelectedPageView = $('.sort-form').find("input[name='pv']").val();
	search_params.set("paymentOption", userSelectedPaymentOption);
	search_params.set("pv", userSelectedPageView);
	$(obj).attr('href', url.href);
}

